import React from 'react';
import { Card } from '@epcbuilder/lib/components';
import PdfViewer from './PdfViewer';

const PdfReportCard = () => {
  return (
    <div className="flex h-full flex-col gap-6 xl:flex-row">
      <Card className="w-full overflow-hidden will-change-transform xl:min-w-full">
        <div
          className="border-primary h-[475px] w-full overflow-hidden rounded-[16px] border-2 xl:h-[470px]"
          id="pdf-viewer"
        >
          <PdfViewer />
        </div>
      </Card>
    </div>
  );
};

export default PdfReportCard;
