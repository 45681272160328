import { ImprovementDto } from '@epcbuilder/lib/models/jobs';
import { EpcRating } from '@epcbuilder/lib/models/properties';
import { GrantSchemes } from '@/components/epc-wizard/steps/epc-rating/ecoFlexQualifyingChecker';

export enum EpcWizardSteps {
  EPC_RATING = 1,
  ENERGY_ASSESSMENT = 2,
  IMPROVEMENT_PLAN = 3,
  INSTALL = 4,
  JOB_COMPLETE = 5,
}

export const EpcWizardStepsStrings = {
  [EpcWizardSteps.EPC_RATING]: 'EPC Rating',
  [EpcWizardSteps.ENERGY_ASSESSMENT]: 'Energy Assessment',
  [EpcWizardSteps.IMPROVEMENT_PLAN]: 'Improvement Plan',
  [EpcWizardSteps.INSTALL]: 'Install',
  [EpcWizardSteps.JOB_COMPLETE]: 'Job Complete',
};

export type BookingAvailability = {
  bookings: BookingAvailabilitySlot[];
};

export enum BookingSlot {
  Am = 1,
  Pm = 2,
}

export type BookingAvailabilitySlot = {
  date: string;
  slot: BookingSlot;
  available: boolean;
};

export type BookingAvailabilityRequested = {
  dateRequested: Date;
  amRequested: boolean;
  pmRequested: boolean;
};

export type SurveyBooking = {
  id: string;
  surveyId: string;
  assessorName: string;
  assessorId: string;
  surveyDate: Date;
  bookingSlot: BookingSlot;
  state: number;
  notes: string;
  active: boolean;
  goCardlessPaymentId?: string;
};

export type SurveyBookingStateUpdate = {
  state: number;
};

export type SurveyFormBooking = Omit<SurveyBooking, 'surveyId' | 'assessorName' | 'id' | 'state' | 'active'>;

export type SurveyBookingDetails = {
  id: string;
  surveyId: string;
  assessorName: string;
  assessorId: string;
  surveyDate: Date;
  bookingSlot: BookingSlot;
  paid: string;
  notes: string;
};

export type SurveyBookingForm = {
  surveyId: string;
  surveyDate: Date;
  slot: BookingSlot;
  state: number;
  active: boolean;
};

export type EligiblityFormData = {
  marketingConsent: boolean;
  datamatchConsent: boolean;
  eligible: boolean;
  eligibleSchemes?: GrantSchemes[];
  benefits: string[];
  flexCriteria: string[];
  incomeLess31k?: boolean;
};

export type EligibilityStatus = {
  eligibility: boolean;
  filledInForm: boolean;
};

export enum JobContact {
  SURVEY = 1,
  INSTALLATION = 2,
}

export type InstallationBooking = {
  id: string;
  bookingDateTime: Date;
  assessor: string;
  notes: string;
  selectedImprovement?: ImprovementDto;
};

export type Filters = {
  currentEpcFilters: EpcRating[];
  search: string;
  showExpired: boolean;
  showCompleted: boolean;
};

export type JobImprovementDetailsDto = {
  jobId: number;
  improvementPlanId: number;
  planName: string;
  totalCostPounds: number;
  yearlyMonetarySavingsPounds: number;
  yearlyCo2SavingsKg: number;
  improvementItems: Array<JobImprovementItemDto>;
  availableToSelect: boolean;
  isSelected: boolean;
  newEpcRating?: string;
};

export type JobImprovementItemDto = {
  itemName?: string;
  itemCostPounds: number;
  monetarySavings?: number;
  co2Savings?: number;
};
