import React from 'react';
import { NewButton } from '@epcbuilder/lib/components/Buttons';
import { GrantSchemes } from '../ecoFlexQualifyingChecker';
import EligibilityResults from './EligibilityResults';
import EligibilityStageContainer from './EligibilityStageContainer';

const EligibilityStageResults = ({
  eligibleFor,
  onContinue,
}: {
  eligibleFor: GrantSchemes[];
  onContinue: () => void;
}) => {
  return (
    <EligibilityStageContainer title="Here are your results!">
      <p className="leading-7">Based on the information you have provided you or your property qualifies for:</p>
      <EligibilityResults eligibleFor={eligibleFor} />
      <p className="leading-7">
        Our team will be in touch with you shortly to let you know about the next steps. If you'd like to speak to a
        member of the team about this please call{' '}
        <a className="text-primary" href="tel:08000584140">
          0800 058 4140
        </a>
        .
      </p>
      <NewButton id="start-questionnaire" text="Continue" onClick={onContinue} />
    </EligibilityStageContainer>
  );
};

export default EligibilityStageResults;
