import React, { Dispatch, SetStateAction, useState } from 'react';
import { EpcWizardSteps } from '@/models/job';
import { CallUsCard } from '../ContactCards';
import ImprovementPlansDrawer from './ImprovementPlansDrawer/ImprovementPlansDrawer';
import LearnMoreCard from './LearnMoreModal';
import NextStepsCard from './NextStepsCard';
import { DownloadPdfCard } from './PdfDownloadCard';
import PdfReportCard from './PdfReportCard';

interface IImprovementPlanStepProps {
  setActiveWizardStep: Dispatch<SetStateAction<EpcWizardSteps>>;
}

const ImprovementPlanStep = ({ setActiveWizardStep }: IImprovementPlanStepProps) => {
  const [showPlansDrawer, setShowPlansDrawer] = useState<boolean>(false); // set true for testing, should be false by default

  return (
    <div className="flex items-center justify-center">
      <div className="grid justify-center gap-6 md:grid-cols-2 lg:grid-cols-12">
        <div className="w-full md:col-span-4 lg:col-span-7 lg:row-span-3 xl:col-span-8 xl:row-span-3">
          <PdfReportCard />
        </div>
        <>
          <div className="order-2 flex flex-row gap-6 md:order-1 md:col-span-1 lg:order-1 lg:col-span-5 lg:row-span-1 xl:col-span-4 xl:row-span-1">
            <div className="size-[150px] flex-1">
              <DownloadPdfCard />
            </div>
            <div className="size-[150px] flex-1">
              <a href="tel:08000584140" className="flex-1">
                <CallUsCard />
              </a>
            </div>
          </div>
          <div className="order-3 flex flex-col md:col-span-4 lg:order-2 lg:col-span-5 lg:row-span-1 xl:col-span-4">
            <LearnMoreCard
              text="Do you want to learn more about different installment measures?"
              url="https://epcbuilder.com/install-measures"
            />
          </div>
          <div className="order-1 flex flex-col md:order-2 md:col-span-3 lg:order-3 lg:col-span-5 lg:row-span-1 xl:col-span-4">
            <NextStepsCard setShowPlansDrawer={setShowPlansDrawer} />
          </div>
        </>
      </div>

      {showPlansDrawer && (
        <ImprovementPlansDrawer setShowPlansDrawer={setShowPlansDrawer} setActiveWizardStep={setActiveWizardStep} />
      )}
    </div>
  );
};

export default ImprovementPlanStep;
