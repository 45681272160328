import React, { createContext, useContext } from 'react';
import { Children } from '@epcbuilder/lib/utils';
import { KeyedMutator } from 'swr';
import useJobImprovements from '@/hooks/jobs/useJobImprovements';
import { JobImprovementDetailsDto } from '@/models/job';

type JobImprovementsContextProps = {
  jobImprovements?: Array<JobImprovementDetailsDto>;
  error: unknown;
  mutate: KeyedMutator<Array<JobImprovementDetailsDto>>;
  isLoading: boolean;
};

const JobImprovementsContext = createContext<JobImprovementsContextProps | undefined>(undefined);

const JobImprovementsProvider = ({ jobId, children }: { jobId: string; children: Children }) => {
  const { jobImprovements, error, mutate, isLoading } = useJobImprovements({ id: jobId });

  const contextValue: JobImprovementsContextProps = {
    jobImprovements,
    error,
    mutate,
    isLoading,
  };

  return <JobImprovementsContext.Provider value={contextValue}>{children}</JobImprovementsContext.Provider>;
};

const useJobImprovementsContext = () => {
  const context = useContext(JobImprovementsContext);
  if (!context) {
    throw new Error('useJobImprovements must be used within a JobImprovementsProvider');
  }
  return context;
};

export { JobImprovementsProvider, useJobImprovementsContext };
