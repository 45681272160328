import React from 'react';
import { useFormContext } from 'react-hook-form';
import { NewButton } from '@epcbuilder/lib/components/Buttons';
import { EligibilityFormData, IEligibilityFormNavigation } from './EligibilityModal';
import EligibilityResults from './EligibilityResults';
import EligibilityStageContainer from './EligibilityStageContainer';
import FormCheckboxGroup from './FormCheckboxGroup';

const consentOptions = [
  {
    label:
      'I understand that by submitting this form, I fully consent to the processing of my personal data and to be contacted by email and/or phone in order to process my ECO funding.',
    value: 'consent',
  },
];

const EligibilityStageIntroduction = ({ forward }: IEligibilityFormNavigation) => {
  const { setValue, watch } = useFormContext<EligibilityFormData>();

  const consent = watch('consent');

  const onItemSelect = (_: string, isChecked: boolean) => {
    setValue('consent', isChecked);
  };

  return (
    <EligibilityStageContainer title="Do you qualify for free grants?">
      <p className="leading-7">
        If you qualify for the ECO 4 or Flex schemes <span className="font-bold">your survey will be free</span> and
        you're entitled to government funding to pay for your installation.
      </p>
      <EligibilityResults />
      <p className="leading-7">Fill out this short questionnaire to find out what you're entitled to!</p>
      <FormCheckboxGroup
        items={consentOptions}
        itemStyle="no-background"
        onItemSelect={onItemSelect}
        selectedValues={consent ? ['consent'] : []}
      />
      <NewButton
        onClick={() => forward()}
        disabled={!consent}
        id="eligibility-next"
        text="Start The Questionnaire"
        sizeClasses="w-full md:w-[50%]"
      />
    </EligibilityStageContainer>
  );
};

export default EligibilityStageIntroduction;
