import React from 'react';
import { MdCheck } from 'react-icons/md';

const ProgressBar = ({ currentStep }: { currentStep: number }) => {
  const steps = [
    'Personal Details',
    'Payment Details',
    'Order Summary',
    'Redirect to GoCardless',
    'Booking Confirmation',
  ];

  return (
    <div>
      {/* Desktop View */}
      <div
        id="progress-bar"
        className="bg-color-secondary fixed bottom-0 left-0 z-10 hidden h-[95%] w-[270px] rounded-tl-xl px-8 pt-12 shadow-lg md:block lg:px-8 xl:px-8"
      >
        <ol className="relative flex flex-col gap-8">
          <div className="absolute left-[11px] top-[1.85rem] flex flex-col items-center gap-9">
            {steps.slice(0, -1).map((_, index) => (
              <div
                key={index}
                id={`progress-bar-text-${index}`}
                className={`h-5 w-[2px] ${
                  index < currentStep - 1
                    ? 'bg-primary-darker dark:bg-primary'
                    : index === currentStep - 1
                      ? 'bg-primary'
                      : 'bg-blue-dark dark:bg-[#195E55]'
                }`}
              />
            ))}
          </div>

          {steps.map((label, index) => (
            <li key={index} className="relative flex flex-row items-center gap-4" id={`progress-step-${index}`}>
              <div
                className={`flex size-[24px] items-center justify-center rounded-full text-[14px] font-bold ${
                  currentStep > index + 1 ? 'border-primary border-2 p-0.5' : ''
                }`}
              >
                <div
                  id={`progress-bar-${index}`}
                  className={`flex size-full justify-center rounded-full ${
                    currentStep > index + 1
                      ? 'bg-primary text-white'
                      : currentStep === index + 1
                        ? 'bg-primary-darker dark:bg-primary text-white'
                        : 'bg-blue-dark text-white dark:bg-[#195E55]'
                  }`}
                >
                  <p className="text-[14px]">{currentStep > index + 1 ? <MdCheck size={16} /> : index + 1}</p>
                </div>
              </div>

              <p id={`progress-label-${index}`}>{label}</p>
            </li>
          ))}
        </ol>
      </div>

      {/* Mobile View */}
      <div className="sticky left-0 top-0 z-10 block h-2 w-full bg-[#DCE1E3] md:hidden">
        <div style={{ width: `${(currentStep / steps.length) * 100}%` }} className="h-2 bg-[#2A9C8E]" />
      </div>
    </div>
  );
};

export default ProgressBar;
