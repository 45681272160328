import React, { Dispatch, SetStateAction } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Modal, TextInput } from '@epcbuilder/lib/components';
import { NewButton } from '@epcbuilder/lib/components/Buttons';
import { PropertyAccessDetails } from '@epcbuilder/lib/models/properties';
import { handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { EMAIL_REGEX, PHONE_REGEX } from '@epcbuilder/lib/utils/generic';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { usePropertyContext } from '@/context/usePropertyContext';
import { putPropertyAccessDetails } from '@/network/properties';

const accessDetailsSchema = yup.object().shape({
  firstName: yup.string().required('First Name must not be empty'),
  lastName: yup.string().required('Last Name must not be empty'),
  email: yup.string().matches(EMAIL_REGEX, 'Email is not a valid email address').required('Email must not be empty'),
  phone: yup.string().matches(PHONE_REGEX, 'Phone is not a valid UK phone number').required('Phone must not be empty'),
  notes: yup.string(),
});

const AccessDetailsModal = ({
  eligiblityCheck,
  landlordManaged,
  proceedToEmailTenantModal,
  setShowEligibilityModal,
  setShowConfirmEmailToTenantModal,

  onClose,
}: {
  eligiblityCheck: boolean;
  landlordManaged: boolean;
  proceedToEmailTenantModal: boolean;
  setShowEligibilityModal?: Dispatch<SetStateAction<boolean>>;
  setShowConfirmEmailToTenantModal?: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
}) => {
  const { property, mutate: refetchProperty } = usePropertyContext();

  const defaultValues: PropertyAccessDetails = {
    firstName: property?.accessDetails.firstName ?? '',
    lastName: property?.accessDetails.lastName ?? '',
    email: property?.accessDetails.email ?? '',
    phone: property?.accessDetails.phone ?? '',
    notes: property?.accessDetails.notes ?? '',
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<PropertyAccessDetails>({
    defaultValues,
    resolver: yupResolver(accessDetailsSchema),
    reValidateMode: 'onSubmit',
  });

  const onSubmit: SubmitHandler<PropertyAccessDetails> = async (data) => {
    try {
      await putPropertyAccessDetails({
        id: property?.id,
        data: {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email.trim(),
          phone: data.phone.trim(),
          notes: data.notes,
        },
      });
      refetchProperty();
      if (eligiblityCheck) {
        if (landlordManaged && proceedToEmailTenantModal) {
          setShowConfirmEmailToTenantModal && setShowConfirmEmailToTenantModal(true);
        } else if (!landlordManaged) {
          setShowEligibilityModal && setShowEligibilityModal(true);
        }
      }
      onClose();
    } catch (error: unknown) {
      handleUnknownDetail(error);
    }
  };

  return (
    <Modal onClose={onClose}>
      <form className="flex max-h-none flex-col gap-4 md:max-h-[300px] lg:max-h-none" onSubmit={handleSubmit(onSubmit)}>
        <h1 className="text-left text-[24px]">{!landlordManaged ? 'Access details' : 'Tenant details'}</h1>
        <TextInput
          {...register('firstName')}
          id="firstName"
          name="firstName"
          title="Tenants First Name"
          placeholder="First name"
          label="First Name"
          error={errors.firstName?.message}
        />
        <TextInput
          {...register('lastName')}
          id="lastName"
          name="lastName"
          title="Tenants Last Name"
          placeholder="Last name"
          label="Last Name"
          error={errors.lastName?.message}
        />
        <TextInput
          {...register('email')}
          id="email"
          name="email"
          title="Tenants Email"
          placeholder="Email"
          label="Email"
          error={errors.email?.message}
        />
        <TextInput
          {...register('phone')}
          id="phone"
          name="phone"
          title="Tenants Phone"
          placeholder="Phone"
          label="Phone Number"
          error={errors.phone?.message}
        />
        <div>
          <p className="mb-2 font-bold">Notes</p>
          <textarea
            placeholder="Is there anything else you'd like us to know? (Optional)"
            {...register('notes')}
            id="notes"
            className="text-color bg-color-secondary border-blue-lighter dark:border-primary-darker focus:border-primary-lighter h-20 w-full rounded-xl border-2 px-4 py-1 text-base outline-none"
          />
        </div>
        <div className="pb-0 md:pb-6 lg:pb-0">
          <NewButton id="saveButton" loading={isSubmitting} type="submit" text="Save" />
        </div>
      </form>
    </Modal>
  );
};

export default AccessDetailsModal;
