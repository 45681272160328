import React from 'react';
import { useFormContext } from 'react-hook-form';
import { NewButton } from '@epcbuilder/lib/components/Buttons';
import { EligibilityFormData, IEligibilityFormNavigation } from './EligibilityModal';
import EligibilityStageContainer from './EligibilityStageContainer';
import FormRadioGroup from './FormRadioGroup';
import ProgressBar from './ProgressBar';

const radioOptions = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];

const EligibilityStageHouseholdIncome = ({ forward }: IEligibilityFormNavigation) => {
  const { setValue, watch } = useFormContext<EligibilityFormData>();
  const selectedValue = watch('incomeLess31k') === undefined ? undefined : watch('incomeLess31k') ? 'yes' : 'no';

  const onItemSelect = (value: string) => {
    setValue('incomeLess31k', value === 'yes');
  };

  return (
    <EligibilityStageContainer>
      <ProgressBar progress={1} />
      <h1>Is your household income below £31,000?</h1>
      <FormRadioGroup onItemSelect={onItemSelect} selectedValue={selectedValue} items={radioOptions} />
      <NewButton disabled={selectedValue === undefined} onClick={() => forward()} id="eligibility-next" text="Next" />
    </EligibilityStageContainer>
  );
};

export default EligibilityStageHouseholdIncome;
