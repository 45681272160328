import React from 'react';
import { MdOutlinePhone, MdOutlinePhoneCallback } from 'react-icons/md';

export const CallUsCard = () => {
  return (
    <div className="bg-light-dark dark:bg-dark-dark shadow-grey-sm dark:shadow-dark-sm dark:hover:shadow-brand-sm hover:shadow-brand-sm relative flex size-full cursor-pointer flex-col gap-4 rounded-[22px] p-6 ">
      <div id="call-us-now-button" className="flex size-full flex-col items-center justify-center gap-2">
        <MdOutlinePhone size={32} className="text-color-brand" />
        <p className="text-center font-bold">Call us now</p>
      </div>
    </div>
  );
};

export const RequestCallbackCard = ({ onClick }: { onClick: () => void }) => {
  return (
    <button
      onClick={onClick}
      className="bg-light-dark dark:bg-dark-dark shadow-grey-sm dark:shadow-dark-sm dark:hover:shadow-brand-sm hover:shadow-brand-sm relative flex size-full cursor-pointer flex-col gap-4 rounded-[22px] p-6"
    >
      <div id="request-callback-button" className="flex size-full flex-col items-center justify-center gap-2">
        <MdOutlinePhoneCallback size={32} className="text-color-brand" />
        <p className="text-center font-bold">Request a callback</p>
      </div>
    </button>
  );
};
