import React from 'react';
import { Container } from '@epcbuilder/lib/components';
import { JobProvider } from '@/context/useJobContext';
import { JobImprovementsProvider } from '@/context/useJobImprovementsContext';
import { PropertyProvider } from '@/context/usePropertyContext';
import EpcWizard from '../epc-wizard';

const Property = ({ jobId, propertyId }: { jobId: string; propertyId: string }) => {
  return (
    <PropertyProvider id={propertyId}>
      <JobProvider id={jobId}>
        <JobImprovementsProvider jobId={jobId}>
          <Container>
            <EpcWizard />
          </Container>
        </JobImprovementsProvider>
      </JobProvider>
    </PropertyProvider>
  );
};

export default Property;
