import React from 'react';
import { FullScreenModal } from '@epcbuilder/lib/components';
import { NewButton } from '@epcbuilder/lib/components/Buttons';

const PaymentFailedModal = ({ onClose }: { onClose: () => void }) => {
  return (
    <FullScreenModal onClose={onClose}>
      <div className="items-left flex flex-col justify-center px-4 lg:w-[70%] lg:px-12">
        <h1 id="payment-failed-heading" className="font-header mb-6 w-full text-left text-2xl">
          Payment Failed
        </h1>
        <p>Payment failed. Please try again or contact support.</p>
        <div className="mt-6 w-36">
          <NewButton id="view-booking-details-button" text="Retry" variant="primary" onClick={onClose} />
        </div>
      </div>
    </FullScreenModal>
  );
};

export default PaymentFailedModal;
