import React from 'react';
import { Modal } from '@epcbuilder/lib/components';
import { format } from 'date-fns';
import { SurveyBooking } from '@/models/job';

export const ConfirmCancellationModal = ({ value, onClose }: { value?: SurveyBooking; onClose: () => void }) => {
  return (
    <Modal id="booking-cancellation-confirmation-modal" onClose={onClose}>
      <h1 className="font-header text-lg">Cancellation Successful</h1>
      <p className="mt-4">
        Your appointment on{' '}
        <span className="font-bold">
          {value && format(new Date(value.surveyDate), 'dd/MM/yyyy')}
          {value && (value.bookingSlot === 1 ? ' AM' : ' PM')}
        </span>{' '}
        is successfully canceled and we are processing your refund now. It can take up to 5 working days.
      </p>
    </Modal>
  );
};

export default ConfirmCancellationModal;
