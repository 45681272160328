import React from 'react';
import { RadioButton } from '@epcbuilder/lib/components';
import { Children } from '@epcbuilder/lib/utils';

interface IFormRadio {
  label: string;
  value: string;
}

const FormRadio = ({
  option,
  onItemSelect,
  selectedValue,
  children,
}: {
  option: IFormRadio;
  onItemSelect: (value: string) => void;
  selectedValue: string | undefined;
  children: Children;
}) => {
  const isSelected = option.value === selectedValue;
  const labelBackgroundClasses = isSelected
    ? 'bg-blue-dark dark:bg-primary-darker'
    : 'bg-blue-lighter dark:bg-[#195E55]';

  return (
    <label
      className={`${labelBackgroundClasses} flex min-h-10 w-full shrink-0 cursor-pointer items-center gap-2 rounded-[8px] p-2 text-sm duration-200`}
    >
      <RadioButton onChange={onItemSelect} sizeClass="size-5" value={option.value} selectedValue={selectedValue} />
      {children}
    </label>
  );
};

const FormRadioGroup = ({
  items,
  onItemSelect,
  selectedValue,
}: {
  items: IFormRadio[];
  onItemSelect: (value: string) => void;
  selectedValue: string | undefined;
}) => {
  return (
    <ul className="flex flex-col gap-2">
      {items.map((option, index) => (
        <FormRadio onItemSelect={onItemSelect} selectedValue={selectedValue} key={index} option={option}>
          {option.label}
        </FormRadio>
      ))}
    </ul>
  );
};

export default FormRadioGroup;
