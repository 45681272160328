import { RefundReason } from '@epcbuilder/lib/models/payments';
import { api } from '@epcbuilder/lib/utils';

export type Payment = {
  surveyBookingId: string;
  bankAccountId: string;
};

export const postPayment = async (data: Payment) => {
  return await api.post(`/payment?surveyBookingId=${data.surveyBookingId}&bankAccountId=${data.bankAccountId}`);
};

export const postPaymentPoll = async ({ surveyBookingId }: { surveyBookingId: string }) => {
  return await api.post(`/payment/poll?surveyBookingId=${surveyBookingId}`);
};

export const postRefund = async ({
  paymentId,
  refundReason,
  refundComments,
  reference,
  idempotencyKey,
}: {
  paymentId: string;
  refundReason: RefundReason;
  refundComments?: string;
  reference?: string;
  idempotencyKey?: string;
}) => {
  return await api.post(`/payment/${paymentId}/refund`, {
    refundReason,
    refundComments,
    reference,
    idempotencyKey,
  });
};

export const getPayments = async () => {
  return await api.get(`/payment`);
};
