import React from 'react';
import { MdOutlineErrorOutline } from 'react-icons/md';
import { Card } from '@epcbuilder/lib/components';
import { PropertyRating } from '@epcbuilder/lib/models/properties';
import { format, isBefore } from 'date-fns';

const PropertyDetailsCard = ({ propertyRatings }: { propertyRatings: PropertyRating | undefined }) => {
  return (
    <Card className="flex size-full justify-center">
      <div className="flex flex-col">
        <div className="flex flex-col">
          <div className="text-canter flex flex-1 flex-row items-center p-2">
            <p className="flex-[3] font-bold">Current EPC:</p>
            <p className="flex-[2]" id="currentRating">
              {propertyRatings?.currentRating || 'N/A'}
            </p>
          </div>

          <div className="flex flex-1 flex-row items-center p-2 text-left">
            <p className="flex-[3] font-bold">Potential EPC:</p>
            <p className="flex-[2]" id="potentialRating">
              {propertyRatings?.potentialRating || 'N/A'}
            </p>
          </div>
        </div>
        <div className="bg-brand-gradient dark:bg-dark-brand-gradient h-[0.2rem] w-full" />
        <div className="flex flex-col">
          <div className="flex flex-1 flex-row items-center p-2 text-left">
            <p className="flex-[3] font-bold">Inspection date:</p>
            <p className="flex-[2] text-base" id="ratingCreatedOn">
              {propertyRatings?.ratingCreatedOn
                ? format(new Date(propertyRatings?.ratingCreatedOn), 'dd/MM/yyyy')
                : 'N/A'}
            </p>
          </div>
          <div
            className={`${propertyRatings?.certificateValidUntil && isBefore(new Date(propertyRatings?.certificateValidUntil), new Date()) && 'bg-error-light/30'} relative flex flex-1 flex-row items-center rounded-[18px] p-2 text-left`}
          >
            <p className="flex-[3] font-bold">Valid until:</p>
            <p className="flex-[2] text-base" id="certificateValidUntil">
              {propertyRatings?.certificateValidUntil
                ? format(new Date(propertyRatings?.certificateValidUntil), 'dd/MM/yyyy')
                : 'N/A'}
            </p>
            {propertyRatings?.certificateValidUntil &&
              isBefore(new Date(propertyRatings?.certificateValidUntil), new Date()) && (
                <div className="bg-error-light absolute -right-3 rounded-full p-1">
                  <MdOutlineErrorOutline className="text-white" size={22} />
                </div>
              )}
          </div>
        </div>
        <div className="bg-brand-gradient dark:bg-dark-brand-gradient h-[0.2rem] w-full" />
        <div className="flex flex-col">
          <div className="flex flex-1 flex-row items-center p-2 text-left">
            <p className="flex-[3] font-bold">Property type:</p>
            <p className="flex-[2] text-base" id="propertyType">
              {propertyRatings?.propertyType || 'N/A'}
            </p>
          </div>
          <div className="flex flex-1 flex-row items-center p-2 text-left">
            <p className="flex-[3] font-bold">Build form:</p>
            <p className="flex-[2] text-base" id="builtForm">
              {propertyRatings?.builtForm || 'N/A'}
            </p>
          </div>
        </div>
        <div className="bg-brand-gradient dark:bg-dark-brand-gradient h-[0.2rem] w-full" />
        <div className="flex flex-col">
          <div className="flex flex-1 flex-row items-center p-2 text-left">
            <p className="flex-[3] font-bold">Current Co2:</p>
            <p className="flex-[2] text-base" id="co2EmissionsCurrent">
              {propertyRatings?.co2EmissionsCurrent || 'N/A'}
            </p>
          </div>
          <div className="flex flex-1 flex-row items-center p-2 text-left">
            <p className="flex-[3] font-bold">Potential Co2:</p>
            <p className="flex-[2] text-base" id="co2EmissionsPotential">
              {propertyRatings?.co2EmissionsPotential || 'N/A'}
            </p>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default PropertyDetailsCard;
