import React, { Dispatch, SetStateAction, useState } from 'react';
import { Checkbox } from '@epcbuilder/lib/components';
import { NewButton } from '@epcbuilder/lib/components/Buttons';
import { useTheme } from '@epcbuilder/lib/context/themeContext';
import { EpcWizardSteps, JobImprovementDetailsDto } from '@/models/job';
import { PlanDrawerStep } from './ImprovementPlansDrawer';

interface IFinanceServiceStepProps {
  setPlanDrawerStep: Dispatch<SetStateAction<PlanDrawerStep>>;
  setActiveWizardStep: Dispatch<SetStateAction<EpcWizardSteps>>;
  closeDrawer: () => void;
  selectedPlan?: JobImprovementDetailsDto;
}

const SelinaLogo = () => {
  const { theme } = useTheme();
  const selinaLogoLightSrc = '/images/selina-logo-dark.png';
  const selinaLogoDarkSrc = '/images/selina-logo-light.png';
  const selinaLogoSrc = theme === 'light' ? selinaLogoLightSrc : selinaLogoDarkSrc;
  return <img className="w-fit" src={selinaLogoSrc} alt="Selina logo" />;
};

const CheckboxLabel = () => {
  return (
    <div>
      By checking here, you confirm that you agree with Selina Finance's{' '}
      <a
        className="font-bold underline"
        href="https://www.selinafinance.co.uk/legal/terms-of-use"
        target="_blank"
        rel="noreferrer"
      >
        Terms of use
      </a>{' '}
      and{' '}
      <a
        className="font-bold underline"
        href="https://www.selinafinance.co.uk/legal/privacy-policy"
        target="_blank"
        rel="noreferrer"
      >
        Privacy Policy
      </a>
      .
    </div>
  );
};

const FinanceServiceStep = ({
  setPlanDrawerStep,
  setActiveWizardStep,
  closeDrawer,
  selectedPlan,
}: IFinanceServiceStepProps) => {
  const [agreedToTerms, setAgreedToTerms] = useState(false);

  if (!selectedPlan) {
    return (
      <div className="w-max-[576px] mx-auto flex flex-col gap-4">
        <h2>No plan found</h2>
      </div>
    );
  }

  return (
    <div className="flex max-w-[576px] flex-col gap-6">
      <SelinaLogo />
      <p className="leading-6 text-[#264653] dark:text-white">
        Heads up - by clicking 'Continue' you will be redirected to the Selina site to fill in the 'quick quote'
        application form.
      </p>
      <div
        id="finance-disclaimer"
        className="border-1 flex flex-col gap-4 rounded-[20px] border-[#DCE1E3] bg-white p-6 text-sm text-[#264653] dark:border-[#516B75] dark:bg-[#153542] dark:text-white"
      >
        <p>
          <span className="font-bold">Disclaimer:</span> Your home may be repossessed if you do not keep up repayments
          on a mortgage or other debt secured on it.
        </p>
        <p>
          A small commission is earned upon activation of a finance plan. More information will be available in the
          documentation you receive from Selina.
        </p>
      </div>
      <Checkbox label={CheckboxLabel()} checked={agreedToTerms} onChange={() => setAgreedToTerms(!agreedToTerms)} />
      <div className="flex gap-2">
        {/* Note: these buttons are wrapped in divs for sizing reasons. 
          Without them, the button enclosed by an <a> tag was smaller than the other */}
        <div className="w-full">
          <NewButton
            id="back-to-finance-summary"
            text="Back"
            variant="secondary"
            onClick={() => setPlanDrawerStep(PlanDrawerStep.OrderSummary)}
          />
        </div>
        {agreedToTerms ? (
          <a className="w-full" href={import.meta.env.VITE_SELINA_QUOTE_URL} target="_blank" rel="noreferrer">
            <NewButton
              onClick={() => {
                setActiveWizardStep(EpcWizardSteps.INSTALL);
                closeDrawer();
              }}
              id="continue-to-selina-quote"
              text="Continue"
              disabled={!agreedToTerms}
            />
          </a>
        ) : (
          <div className="w-full">
            <NewButton id="continue-to-selina-quote" text="Continue" disabled={!agreedToTerms} />
          </div>
        )}
      </div>
    </div>
  );
};

export default FinanceServiceStep;
