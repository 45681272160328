import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { NewButton } from '@epcbuilder/lib/components/Buttons';
import { handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { useJobContext } from '@/context/useJobContext';
import useJobInstallation from '@/hooks/jobs/useJobInstallation';
import { postJobInstallation } from '@/network/jobs';
import GenericNextStepsCard from '../GenericNextStepsCard';

interface INextStepsCardProps {
  setShowPlansDrawer: Dispatch<SetStateAction<boolean>>;
}

const NextStepsCard = ({ setShowPlansDrawer }: INextStepsCardProps) => {
  const { job, mutate: refetchJob } = useJobContext();
  const { error: installationError, mutate: refetchJobInstallation } = useJobInstallation({ id: job?.id });

  const handleCreateInstallation = useCallback(async () => {
    try {
      if (!job?.id) throw Error;
      if (installationError && installationError.detail === 'No installation could be found on the job.') {
        await postJobInstallation({ id: job?.id });
      }
      await refetchJobInstallation();
      await refetchJob();
      setShowPlansDrawer(true);
    } catch (error: unknown) {
      handleUnknownDetail(error);
    }
  }, [installationError, job?.id, refetchJob, refetchJobInstallation, setShowPlansDrawer]);

  return (
    <GenericNextStepsCard>
      <div className="mt-4">
        <NewButton
          id="select-your-plan-button"
          disabled={!job?.active}
          onClick={handleCreateInstallation}
          variant="primary"
          text="Select Your Plan"
        />
      </div>
    </GenericNextStepsCard>
  );
};

export default NextStepsCard;
