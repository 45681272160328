import React from 'react';
import useUser from '@/hooks/auth/useUser';

interface ProfileSideBarProps {
  setActiveTab: (tab: string) => void;
  activeTab: string;
}

const ProfileSideBar: React.FC<ProfileSideBarProps> = ({ setActiveTab, activeTab }) => {
  const { user: profileData } = useUser();
  const userInitials = `${profileData?.firstName.charAt(0)}${profileData?.lastName.charAt(0)}`.toUpperCase();

  return (
    <div
      id="profile-sidebar"
      className="border-neutral sm:dark:bg-dark-dark rounded-s-[20px] sm:mr-4 sm:block sm:h-screen sm:max-h-[740px] sm:w-64 sm:border-r sm:bg-[#F5FFFD]"
    >
      <div className="gap-6 text-center">
        <div
          id="profile-initials"
          className="bg-primary-darker upercase m-auto mt-4 flex size-[6rem] items-center justify-center gap-4 rounded-[80px] sm:size-[7rem] md:size-[10rem]"
        >
          <h1 className="text-[48px] font-bold text-white">{userInitials}</h1>
        </div>
        <p className="mt-6 font-bold">
          {profileData?.firstName} {profileData?.lastName}
        </p>
      </div>
      <div className="mt-12">
        <div className="flex flex-1 cursor-pointer flex-col gap-1 font-semibold ">
          <button
            id="profile"
            className={`hover:bg-blue hover:dark:bg-primary-darker px-6 py-2 text-start ${activeTab === 'profile' ? 'bg-blue dark:bg-primary-darker' : ''}`}
            onClick={() => setActiveTab('profile')}
          >
            Profile
          </button>
          <button
            id="account"
            className={`hover:bg-blue hover:dark:bg-primary-darker px-6 py-2 text-start ${activeTab === 'accountSecurity' ? 'bg-blue dark:bg-primary-darker' : ''}`}
            onClick={() => setActiveTab('accountSecurity')}
          >
            Account Security
          </button>
          <button
            id="payment-methods"
            className={`hover:bg-blue hover:dark:bg-primary-darker px-6 py-2 text-start ${activeTab === 'paymentMethods' ? 'bg-blue dark:bg-primary-darker' : ''}`}
            onClick={() => setActiveTab('paymentMethods')}
          >
            Payment Methods
          </button>
          <button
            id="payment-history"
            className={`hover:bg-blue hover:dark:bg-primary-darker px-6 py-2 text-start ${activeTab === 'paymentHistory' ? 'bg-blue dark:bg-primary-darker' : ''}`}
            onClick={() => setActiveTab('paymentHistory')}
          >
            Payment History
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileSideBar;
