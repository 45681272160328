import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { Card } from '@epcbuilder/lib/components';
import { NewButton } from '@epcbuilder/lib/components/Buttons';
import usePropertyOwnerStatuses from '@epcbuilder/lib/hooks/usePropertyOwnerStatuses';
import { handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { useJobContext } from '@/context/useJobContext';
import { usePropertyContext } from '@/context/usePropertyContext';
import useJobSurvey from '@/hooks/jobs/useJobSurvey';
import { EligibilityStatus, EpcWizardSteps } from '@/models/job';
import { postJobSurvey } from '@/network/jobs';
import GenericNextStepsCard from '../GenericNextStepsCard';

const NextStepsCard = ({
  setActive,
  setShowTenantCheckModal,
  setShowEligibilityModal,
  setShowConfirmEmailToTenantModal,
  jobEligibilityStatus,
  landlordManaged,
}: {
  setActive: Dispatch<SetStateAction<EpcWizardSteps>>;
  setShowTenantCheckModal: Dispatch<SetStateAction<boolean>>;
  setShowEligibilityModal: Dispatch<SetStateAction<boolean>>;
  setShowConfirmEmailToTenantModal: Dispatch<SetStateAction<boolean>>;
  jobEligibilityStatus: EligibilityStatus | undefined;
  landlordManaged: boolean;
}) => {
  const { property } = usePropertyContext();
  const { job, mutate: refetchJob } = useJobContext();
  const { jobSurvey, error: surveyError, mutate: refetchJobSurvey } = useJobSurvey({ id: job?.id });
  const { statuses } = usePropertyOwnerStatuses();

  const isBookSurveyDisabled =
    jobSurvey && (jobSurvey.surveyStatusID === 1 || jobSurvey.surveyStatusID === 5)
      ? false // Button available when surveyStatusID is 1 or 5
      : !jobSurvey
        ? false // Button available when jobSurvey is undefined
        : !jobEligibilityStatus?.eligibility && job?.active;

  const handleCreateSurvey = useCallback(async () => {
    try {
      if (!job?.id) throw Error;
      if (surveyError && surveyError.detail === 'No survey could be found on the job.') {
        await postJobSurvey({ id: job?.id });
      }
      await refetchJobSurvey();
      await refetchJob();
      setActive(EpcWizardSteps.ENERGY_ASSESSMENT);
    } catch (error: unknown) {
      handleUnknownDetail(error);
    }
  }, [job, surveyError, refetchJobSurvey, refetchJob, setActive]);

  const handleEligibility = useCallback(async () => {
    if (
      property?.ownerStatusId === statuses?.find((status) => status.name === 'Landlord / Managing Agent')?.id &&
      (property?.accessDetails.firstName === null ||
        property?.accessDetails.firstName === '' ||
        property?.accessDetails.lastName === null ||
        property?.accessDetails.lastName === '' ||
        property?.accessDetails.email === null ||
        property?.accessDetails.email === '' ||
        property?.accessDetails.phone === null ||
        property?.accessDetails.phone === '')
    ) {
      setShowTenantCheckModal(true);
      return;
    }

    if (landlordManaged) {
      setShowConfirmEmailToTenantModal(true);
    } else {
      setShowEligibilityModal(true);
    }
  }, [
    property,
    setShowEligibilityModal,
    setShowTenantCheckModal,
    setShowConfirmEmailToTenantModal,
    statuses,
    landlordManaged,
  ]);

  if (jobEligibilityStatus?.eligibility) {
    return (
      <GenericNextStepsCard>
        <div className="flex flex-col gap-2">
          <p className="w-full">Great news, it looks like your property is eligible for grant funding!</p>
          <p>One of our friendly team members will be in touch with you shortly to book your assessment.</p>
        </div>
      </GenericNextStepsCard>
    );
  } else {
    return (
      <Card>
        <div className="flex h-full flex-col justify-between sm:flex-row sm:items-center">
          <div className="flex flex-col pr-8 sm:w-[70%] xl:pr-0">
            <p className="pb-2 text-[18px] font-black uppercase">
              Are you eligible for <span className="text-primary">free home upgrades?</span>
            </p>
            <p>
              There are governmental schemes and grants available to improve your house efficiency for free. Take a
              quick check to see if you qualify!
            </p>
          </div>
          <div className="mt-2 flex flex-row-reverse gap-2 sm:mt-0 sm:w-[30%] sm:flex-col ">
            <NewButton
              id="do-i-qualify"
              onClick={handleEligibility}
              disabled={jobEligibilityStatus?.filledInForm || !job?.active}
              text="Check Now"
            />
            <NewButton
              id="book-survey"
              onClick={() => {
                handleCreateSurvey();
                window.scrollTo({ top: 0, behavior: 'instant' });
              }}
              variant="secondary"
              disabled={isBookSurveyDisabled}
              text="Book Survey"
            />
          </div>
        </div>
      </Card>
    );
  }
};

export default NextStepsCard;
