import React from 'react';
import { useFormContext } from 'react-hook-form';
import { NewButton } from '@epcbuilder/lib/components/Buttons';
import { EligibilityFormData, IEligibilityFormNavigation } from './EligibilityModal';
import EligibilityStageContainer from './EligibilityStageContainer';
import FormCheckboxGroup from './FormCheckboxGroup';
import ProgressBar from './ProgressBar';

const benefitOptions = [
  { value: 'childbenefit', label: 'Child Benefit' },
  { value: 'childtaxcredits(ctc)', label: 'Child Tax Credits (CTC)' },
  { value: 'housingbenefit', label: 'Housing Benefit' },
  { value: 'incomesupport(is)', label: 'Income Support (IS)' },
  {
    value: 'incomebasedjobseekersallowance(jsa)',
    label: 'Income-based Jobseekers allowance (JSA)',
  },
  {
    value: 'incomebasedemployment&supportallowance(esa)',
    label: 'Income-based Employment & Support Allowance (ESA)',
  },
  { value: 'pensionguaranteecredit', label: 'Pension Guarantee Credit' },
  { value: 'pensioncreditsavingscredit', label: 'Pension Credit Savings Credit' },
  { value: 'universalcredit(uc)', label: 'Universal Credit (UC)' },
  { value: 'workingtaxcredit(wtc)', label: 'Working Tax Credit (WTC)' },
  { value: 'noneoftheabove', label: 'None of the above' },
];

const EligibilityStageBenefits = ({ forward, back }: IEligibilityFormNavigation) => {
  const { setValue, watch } = useFormContext<EligibilityFormData>();

  const benefits = watch('benefits');

  const onItemSelect = (value: string, isChecked: boolean) => {
    if (value === 'noneoftheabove' && isChecked) {
      setValue('benefits', ['noneoftheabove']);
    } else {
      const newFlexCriteria = isChecked
        ? [...benefits.filter((item) => item !== 'noneoftheabove'), value]
        : [...benefits].filter((item) => item !== value);

      setValue('benefits', newFlexCriteria);
    }
  };

  return (
    <EligibilityStageContainer>
      <ProgressBar progress={3} />
      <h1>Do you, or anyone in the household, receive any of the following benefits?</h1>
      <FormCheckboxGroup items={benefitOptions} onItemSelect={onItemSelect} selectedValues={benefits} />
      <div className="flex flex-row gap-2">
        <NewButton onClick={() => back()} id="eligibility-back" variant="secondary" text="Back" />
        <NewButton onClick={() => forward()} id="eligibility-next" text="Next" disabled={benefits.length === 0} />
      </div>
    </EligibilityStageContainer>
  );
};

export default EligibilityStageBenefits;
