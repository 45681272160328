import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Loading, RadioButton, TextInput } from '@epcbuilder/lib/components';
import { NewButton } from '@epcbuilder/lib/components/Buttons';
import { handleFormErrors } from '@epcbuilder/lib/utils';
import { AxiosErrorData, handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import useCustomerBankAccounts from '@/hooks/bankAccounts/useCustomerBankAccounts';
import { useBelowDesktop } from '@/hooks/media-queries/useMediaQueries';
import { PaymentDetails } from '@/models/payments';
import { postCustomerBankAccount } from '@/network/customers';
import { postPayment } from '@/network/payments';

const paymentDetailsSchema = yup.object().shape({
  accountHolderName: yup
    .string()
    .matches(/^[A-Za-z ]*$/, 'Invalid Account name')
    .required('Account name must not be empty'),
  accountNumber: yup
    .string()
    .required('Account number must not be empty')
    .matches(/^\d{8}$/, 'Account number must be 8 digits'),
  sortCode: yup
    .string()
    .required('Sort code must not be empty')
    .matches(/^\d{2}-\d{2}-\d{2}$/, 'Sort code must be in the format 00-00-00'),
});

const PaymentDetailsForm = ({
  surveyBookingId,
  onNext,
  setGoCardlessUrl,
  onBankAccountSelect,
}: {
  surveyBookingId: string;
  onNext: () => void;
  setGoCardlessUrl: (url: string) => void;
  onBankAccountSelect: (bankAccountId: string) => void;
}) => {
  const {
    register,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm<PaymentDetails>({ resolver: yupResolver(paymentDetailsSchema) });
  const [showNewPaymentForm, setShowNewPaymentForm] = useState(false);
  const [isNewAccount, setIsNewAccount] = useState(false);
  const { bankAccountData, isLoading } = useCustomerBankAccounts();
  const [selectedBankAccountId, setSelectedBankAccountId] = useState<string>();
  const isMobile = useBelowDesktop();
  const [isLoadingPayment, setIsLoadingPayment] = useState(false);

  const handleBankAccountSelection = (bankAccountId: string) => {
    setSelectedBankAccountId(bankAccountId);
    onBankAccountSelect(bankAccountId);
  };

  useEffect(() => {
    if (bankAccountData && bankAccountData.length > 0) {
      setSelectedBankAccountId(bankAccountData[0].bankAccountId);
      onBankAccountSelect(bankAccountData[0].bankAccountId);
    }
  }, [bankAccountData, onBankAccountSelect]);

  const createBankAccount = async (paymentDetails: PaymentDetails) => {
    try {
      const response = await postCustomerBankAccount(paymentDetails);
      if (response?.data?.bankAccountId) {
        return response.data.bankAccountId;
      }
    } catch (error) {
      const { detail, errors } = error as AxiosErrorData;
      if (detail) {
        const displayMessage = detail.split('  ')[0];
        const userMessage =
          displayMessage === 'Validation failed'
            ? 'Invalid bank details. Please check your account number and sort code.'
            : displayMessage;

        setError('accountHolderName', {
          message: userMessage,
        });
      } else {
        setError('accountHolderName', {
          message: 'An unexpected error occurred. Please try again.',
        });
      }
      handleFormErrors<PaymentDetails>(setError, errors);
      handleUnknownDetail(error);
    }
  };

  const proceedToPayment = async (bankAccountId: string) => {
    setIsLoadingPayment(true);
    try {
      const response = await postPayment({ surveyBookingId, bankAccountId });
      if (response) {
        setGoCardlessUrl(response.data);
        onNext();
      }
    } catch (error) {
      const { errors } = error as AxiosErrorData;
      handleFormErrors<PaymentDetails>(setError, errors);
      handleUnknownDetail(error);
    } finally {
      setIsLoadingPayment(false);
    }
  };

  const handleFormSubmit: SubmitHandler<PaymentDetails> = async (paymentDetails) => {
    try {
      let bankAccountId;
      if (isNewAccount) {
        bankAccountId = await createBankAccount(paymentDetails);
        if (bankAccountId) {
          setSelectedBankAccountId(bankAccountId);
          onBankAccountSelect(bankAccountId);
        }
      } else {
        bankAccountId = selectedBankAccountId;
      }

      if (bankAccountId) {
        await proceedToPayment(bankAccountId);
      }
    } catch (error) {
      const { errors } = error as AxiosErrorData;
      handleFormErrors<PaymentDetails>(setError, errors);
      handleUnknownDetail(error);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  //Render Payment Details Form
  if (showNewPaymentForm || !bankAccountData || bankAccountData.length === 0) {
    return (
      <div className="items-left flex flex-col justify-center px-4 md:ml-[15rem] md:w-[calc(100%-290px)] md:pl-8 lg:w-[70%] lg:px-12">
        <h1 id="payment-details-modal-heading" className="font-header w-full text-left text-2xl">
          Payment Details
        </h1>
        <form className="mt-6 flex max-w-2xl flex-col" onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="relative">
            <div className="mb-6 flex gap-4">
              <TextInput
                {...register('accountHolderName')}
                id="account-holder-name"
                label="Account Holder Name"
                placeholder="Enter account holder name"
                error={errors.accountHolderName?.message}
              />
            </div>
            <div className="mb-6 flex gap-4">
              <TextInput
                {...register('accountNumber')}
                id="account-number"
                label="Account Number"
                placeholder="12345678"
                error={errors.accountNumber?.message}
              />
              <TextInput
                {...register('sortCode')}
                id="sort-code"
                label="Sort Code"
                placeholder="00-00-00"
                error={errors.sortCode?.message}
              />
            </div>
          </div>
          <p className="font-bold">
            By continuing, you can review your order summary. Once confirmed, you'll be redirected to our secure payment
            provider, GoCardless, to complete your booking.
          </p>
          <div className="mb-10 mt-6 flex w-full justify-between gap-4 sm:w-64 lg:mb-2">
            <NewButton
              id="submit-form-button"
              variant="primary"
              text="Proceed To Order Summary"
              type="submit"
              onClick={() => setIsNewAccount(true)}
            />
          </div>
        </form>
      </div>
    );
  }
  //Render Bank Account Selection
  if (selectedBankAccountId) {
    return (
      <div className="items-left flex flex-col justify-center px-4 md:ml-[15rem] md:w-[calc(100%-290px)] md:pl-8 lg:w-[70%] lg:px-12">
        <h1 id="payment-details-modal-heading" className="font-header mb-6 w-full text-left text-2xl">
          Payment Details
        </h1>
        <div className="max-w-2xl">
          <p className="font-bold" id="choose-payment-method">
            Choose the payment method:
          </p>
          {bankAccountData?.map(({ bankAccountId, accountHolder, accountNumberEnding }) => (
            <button
              key={bankAccountId}
              id={bankAccountId}
              className={`border-blue-lighter dark:border-primary-darker mt-2 w-full rounded-xl border-2 p-4 ${
                selectedBankAccountId === bankAccountId ? 'bg-gray-100' : ''
              }`}
              onClick={() => handleBankAccountSelection(bankAccountId)}
            >
              <div className="flex items-center">
                <RadioButton
                  id="radio-button"
                  value={bankAccountId}
                  selectedValue={selectedBankAccountId}
                  onChange={() => handleBankAccountSelection(bankAccountId)}
                  className="mr-6"
                />
                <div>
                  <div className="flex flex-row items-center gap-2">
                    <strong>Account Holder:</strong>
                    <p>{accountHolder.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase())}</p>
                  </div>
                  <div>
                    <div className="flex flex-row items-center gap-2">
                      <strong>Account Number:</strong> <p>***{accountNumberEnding}</p>
                    </div>
                  </div>
                </div>
              </div>
            </button>
          ))}
          <p className="mt-6 font-bold">
            By continuing, you can review your order summary. Once confirmed, you'll be redirected to our secure payment
            provider, GoCardless, to complete your booking.
          </p>
          <div className="mb-10 mt-6 flex flex-col gap-4 sm:flex-row sm:justify-between lg:mb-2">
            <NewButton
              id="add-new-payment-method-button"
              variant="secondary"
              text={isMobile ? 'Add New Account' : 'Add New Payment Method'}
              onClick={() => {
                setShowNewPaymentForm(true);
                setIsNewAccount(true);
              }}
            />
            <NewButton
              id="proceed-to-payment-button"
              variant="primary"
              text={isMobile ? 'Proceed' : 'Proceed To Order Summary'}
              onClick={() => {
                setIsNewAccount(false);
                proceedToPayment(selectedBankAccountId);
              }}
              loading={isLoadingPayment}
            />
          </div>
        </div>
      </div>
    );
  }
};

export default PaymentDetailsForm;
