import React, { Dispatch, SetStateAction, useState } from 'react';
import { BsHouseUp } from 'react-icons/bs';
import { GiFootprint } from 'react-icons/gi';
import { MdArrowDropDown } from 'react-icons/md';
import { PiPiggyBank } from 'react-icons/pi';
import { NewAccordion } from '@epcbuilder/lib/components';
import { NewButton } from '@epcbuilder/lib/components/Buttons';
import { convertNumberToGbpString } from '@epcbuilder/lib/utils/string';
import useFinanceCalculator from '@/hooks/finance-calculator/useFinanceCalculator';
import { JobImprovementDetailsDto } from '@/models/job';
import { postJobImprovementSelection } from '@/network/jobs';

interface IImprovementPlanCardProps {
  improvementPlan: JobImprovementDetailsDto;
  selectedPlanId: number | undefined;
  setSelectedPlanId: Dispatch<SetStateAction<number | undefined>>;
  setProcessingSelection: Dispatch<SetStateAction<boolean>>;
  disabled?: boolean;
}

const ImprovementPlanCard = ({
  improvementPlan,
  selectedPlanId,
  setSelectedPlanId,
  setProcessingSelection,
  disabled,
}: IImprovementPlanCardProps) => {
  const [showFinanceBreakdown, setShowFinanceBreakdown] = useState<boolean>(true);
  const [showCostBreakdown, setShowCostBreakdown] = useState<boolean>(false);
  const [showSavingsBreakdown, setShowSavingsBreakdown] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [selectionError, setSelectionError] = useState<string>('');

  const {
    initialPaymentPounds,
    reversionPaymentPounds,
    fullTermCostPounds,
    totalInterestCostPounds,
    productFeePounds,
    annualPercentageRateOfCharge,
    initialApr,
  } = useFinanceCalculator({
    financeCostPounds: improvementPlan.totalCostPounds,
  });

  const handlePlanSelection = async () => {
    setSelectionError('');
    setButtonLoading(true);
    setProcessingSelection(true);

    try {
      await postJobImprovementSelection({
        jobId: improvementPlan.jobId.toString(),
        improvementId: improvementPlan.improvementPlanId.toString(),
      });

      setSelectedPlanId(improvementPlan.improvementPlanId);
    } catch (error) {
      setSelectionError('Error processing selection');
    } finally {
      setProcessingSelection(false);
      setButtonLoading(false);
    }
  };

  return (
    <div
      className={`${selectedPlanId ? (improvementPlan.improvementPlanId === selectedPlanId ? 'dark:bg-dark-dark bg-[#f5fffd]' : 'bg-[#fff] dark:bg-[#264653]') : 'dark:bg-dark-dark'} dark:shadow-dark-sm border-1 shadow-grey-sm flex max-h-fit w-full flex-col gap-4 rounded-[20px] border-[#DCE1E3] p-6 sm:p-4 md:max-w-[360px] lg:p-6 dark:border-[#516B75]`}
    >
      <h2 className="text-2xl">{improvementPlan.planName || 'Improvement Plan'}</h2>

      {/* improvement highlights section */}
      <div className="flex flex-row justify-between md:justify-center md:gap-3 lg:gap-9 xl:gap-12">
        <div className="flex flex-col items-center gap-0.5 text-center">
          <div className="improvementcard-icon-gradientborder">
            <div className="dark:bg-dark-dark size-full rounded-full bg-[#fff] p-3">
              <BsHouseUp className="text-[#85979E] dark:text-[#A8B5BA]" size="30px" />
            </div>
          </div>
          <p className="text-sm font-bold">{improvementPlan.newEpcRating}</p>
          <p className="text-xs">New EPC rating</p>
        </div>
        <div className="flex flex-col items-center gap-0.5 text-center">
          <div className="improvementcard-icon-gradientborder">
            <div className="dark:bg-dark-dark size-full rounded-full bg-[#fff] p-3">
              <PiPiggyBank className="text-[#85979E] dark:text-[#A8B5BA]" size="30px" />
            </div>
          </div>
          <p className="text-sm font-bold">{convertNumberToGbpString(improvementPlan.yearlyMonetarySavingsPounds)}</p>
          <p className="text-xs">Savings per year</p>
        </div>
        <div className="flex flex-col items-center gap-0.5 text-center">
          <div className="improvementcard-icon-gradientborder">
            <div className="dark:bg-dark-dark size-full rounded-full bg-[#fff] p-3">
              {/* no outline version of this so have to make it ourselves */}
              <GiFootprint
                size="30px"
                color="#0000"
                strokeWidth="30"
                className="stroke-[#85979E] dark:stroke-[#A8B5BA]"
              />
            </div>
          </div>
          <p className="text-sm font-bold">{`${improvementPlan.yearlyCo2SavingsKg}kg`}</p>
          <p className="text-xs">Carbon saved</p>
        </div>
      </div>

      {/* finance breakdown section */}
      <div className="flex flex-col gap-2">
        <NewAccordion
          accordionOpen={showFinanceBreakdown}
          onClick={() => setShowFinanceBreakdown((current) => !current)}
          accordionButtonClassName="p-0 border-b-1 border-[#DCE1E3] dark:border-[#516B75]"
          accordionBodyClassName="min-h-0"
          accordionChildren={
            <div className="flex w-full flex-col gap-2">
              <p className="font-sans text-sm text-[#516B75] dark:text-[#DCE1E3]">
                {`A loan of ${convertNumberToGbpString(improvementPlan.totalCostPounds)} over 25 years results in 60 monthly payments of ${convertNumberToGbpString(initialPaymentPounds)} at a fixed annual rate of
                ${initialApr}% and 240 monthly payments of ${convertNumberToGbpString(reversionPaymentPounds)} at a reversion rate of 3.14% above the Bank of England Base
                Rate. The total cost over the full term is ${convertNumberToGbpString(fullTermCostPounds)}, including interest of ${convertNumberToGbpString(totalInterestCostPounds)}, a product fee
                of ${convertNumberToGbpString(productFeePounds)} added to the balance. APRC: ${annualPercentageRateOfCharge}%.`}
              </p>
            </div>
          }
        >
          <div className="flex w-full flex-row justify-between">
            <h3 className="text-left text-base font-normal">{`Initial monthly payment of ${convertNumberToGbpString(initialPaymentPounds)}/mo.* or ${convertNumberToGbpString(improvementPlan.totalCostPounds)}`}</h3>
            <MdArrowDropDown size="24px" className={`min-w-6 ${showFinanceBreakdown ? 'rotate-180' : ''}`} />
          </div>
        </NewAccordion>
      </div>

      {/* cost breakdown section */}
      <div className="flex flex-col gap-2">
        <NewAccordion
          accordionOpen={showCostBreakdown}
          onClick={() => setShowCostBreakdown((current) => !current)}
          accordionButtonClassName="p-0 border-b-1 border-[#DCE1E3] dark:border-[#516B75]"
          accordionBodyClassName="min-h-0"
          accordionChildren={
            <ul className="flex w-full flex-col gap-1">
              {improvementPlan.improvementItems.map((item, index) => (
                <li key={`improvementItem-${index}`} className="flex flex-row items-center justify-between gap-4">
                  <p
                    className={`text-sm font-semibold ${item.itemCostPounds <= 0 ? 'text-[#4c8137] dark:text-[#83c06d]' : ''}`}
                  >
                    {item.itemName}
                  </p>
                  <p className={`text-sm ${item.itemCostPounds <= 0 ? 'text-[#4c8137] dark:text-[#83c06d]' : ''}`}>
                    {convertNumberToGbpString(item.itemCostPounds)}
                  </p>
                </li>
              ))}
            </ul>
          }
        >
          <div className="flex w-full flex-row justify-between">
            <h3 className="text-base font-normal">Cost Breakdown</h3>
            <MdArrowDropDown size="24px" className={`min-w-6 ${showCostBreakdown ? 'rotate-180' : ''}`} />
          </div>
        </NewAccordion>
      </div>

      {/* savings breakdown section */}
      <div className="flex flex-col gap-2">
        <NewAccordion
          accordionOpen={showSavingsBreakdown}
          onClick={() => setShowSavingsBreakdown((current) => !current)}
          accordionButtonClassName="p-0 border-b-1 border-[#DCE1E3] dark:border-[#516B75]"
          accordionBodyClassName="min-h-0"
          accordionChildren={
            <ul className="flex w-full flex-col gap-1">
              {improvementPlan.improvementItems
                .filter((item) => item.itemCostPounds > 0)
                .map((item, index) => (
                  <li key={`improvementItem-${index}`} className="mr-3 grid grid-cols-10 items-center gap-4">
                    <p className="col-span-5 text-sm font-semibold">{item.itemName}</p>
                    <p className="col-span-2 text-right text-sm">
                      {convertNumberToGbpString(item.monetarySavings || 0)}
                    </p>
                    <p className="col-span-2 text-left text-sm">{`${item.co2Savings}kg`}</p>
                    <div className="col-span-1" />
                  </li>
                ))}
            </ul>
          }
        >
          <div className="grid w-full grid-cols-10 items-end">
            <h3 className="col-span-5 text-left text-base font-normal">Savings Per Year</h3>
            <p className="col-span-2 block text-sm md:hidden lg:hidden xl:block">Energy Bill</p>
            <p className="col-span-2 block text-sm md:hidden lg:hidden xl:block">CO₂</p>
            <MdArrowDropDown
              size="24px"
              className={`col-span-1 min-w-6 justify-self-end md:col-span-5 lg:col-span-5 xl:col-span-1 ${showSavingsBreakdown ? 'rotate-180' : ''}`}
            />
          </div>
        </NewAccordion>
      </div>

      {/* select plan button */}
      <div className="flex flex-col gap-2">
        <NewButton
          id={`select-plan-${improvementPlan.improvementPlanId}`}
          text={
            improvementPlan.improvementPlanId === selectedPlanId
              ? 'Selected'
              : improvementPlan.availableToSelect
                ? 'Select This Plan'
                : 'Unable To Select'
          }
          disabled={
            disabled || !improvementPlan.availableToSelect || improvementPlan.improvementPlanId === selectedPlanId
          }
          loading={buttonLoading}
          onClick={handlePlanSelection}
        />
        {selectionError && <p className="dark:text-error-light text-error">{selectionError}</p>}
      </div>
    </div>
  );
};

export default ImprovementPlanCard;
