import useSWR from 'swr';
import { BankAccount } from '@/models/payments';
import { getCustomerBankAccount } from '@/network/customers';

const useCustomerBankAccount = ({ id }: { id?: string }) => {
  const {
    data: bankAccountData,
    error,
    mutate,
    isLoading,
  } = useSWR<BankAccount>(id ? `customer-bank-account-${id}` : null, async () =>
    id ? await getCustomerBankAccount({ id }) : null
  );

  return {
    bankAccountData,
    error,
    isLoading,
    mutate,
  };
};

export default useCustomerBankAccount;
