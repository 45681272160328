import { api } from '@epcbuilder/lib/utils';
import { CustomerDetails, PaymentDetails } from '@/models/payments';

export const patchCustomer = async (data: CustomerDetails) => {
  return await api.patch(`/customer`, data);
};

export const postCustomer = async (data: CustomerDetails) => {
  return await api.post(`/customer`, data);
};

export const getCustomer = async () => {
  return await api.get(`/customer`);
};

export const deleteCustomer = async () => {
  return await api.delete(`/customer`);
};

export const postCustomerBankAccount = async (data: PaymentDetails) => {
  return await api.post(`/customer/bank-account`, data);
};

export const getCustomerBankAccounts = async () => {
  return await api.get(`/customer/bank-accounts`);
};

export const deleteCustomerBankAccount = async (id: string) => {
  return await api.delete(`/customer/bank-accounts/${id}`);
};

export const getCustomerBankAccount = async ({ id }: { id?: string }) => {
  return await api.get(`/customer/bank-accounts/${id}`);
};
