import React, { Dispatch, SetStateAction, useState } from 'react';
import { MdArrowForward } from 'react-icons/md';
import { NewButton } from '@epcbuilder/lib/components/Buttons';
import { JobImprovementDetailsDto } from '@/models/job';
import ImprovementPlanCard from './ImprovementPlanCard';
import { PlanDrawerStep } from './ImprovementPlansDrawer';

interface ISelectYourPlanStepProps {
  setPlanDrawerStep: Dispatch<SetStateAction<PlanDrawerStep>>;
  selectedPlanId: number | undefined;
  setSelectedPlanId: Dispatch<SetStateAction<number | undefined>>;
  improvementPlans: Array<JobImprovementDetailsDto> | undefined;
}

const SelectYourPlanStep = ({
  setPlanDrawerStep,
  selectedPlanId,
  setSelectedPlanId,
  improvementPlans,
}: ISelectYourPlanStepProps) => {
  const [processingSelection, setProcessingSelection] = useState<boolean>(false);

  return (
    <div className="flex max-w-[1128px] flex-col items-start gap-6">
      <div className="flex flex-col gap-2">
        <h2 className="text-2xl">Select Your Plan</h2>
        <p>
          {improvementPlans &&
            improvementPlans.length > 0 &&
            `We've put together ${(improvementPlans?.length ? ['one', 'two', 'three'][improvementPlans.length - 1] : 'a few') || 'a few'} tailored improvement packages based on your survey results. Choose the plan that best
        fits your needs to start enhancing your home's energy efficiency.`}
        </p>
      </div>
      <div className="flex w-full flex-col gap-6 sm:gap-4 md:flex-row lg:gap-6">
        {improvementPlans && improvementPlans.length > 0 ? (
          improvementPlans.map((improvementPlan, index) => (
            <ImprovementPlanCard
              key={`improvementPlan-${index}`}
              improvementPlan={improvementPlan}
              selectedPlanId={selectedPlanId}
              setSelectedPlanId={setSelectedPlanId}
              setProcessingSelection={setProcessingSelection}
              disabled={processingSelection}
            />
          ))
        ) : (
          <p>No improvement plans found</p> // check this copy / what should we display if improvement plans come back empty
        )}
      </div>
      <div className="border-t-1 w-full border-[#DCE1E3] pt-4 dark:border-[#516B75]">
        <p>
          If you're unsure or have any questions, our team is here to help. Feel free to{' '}
          <a className="font-bold underline" href="tel:08000584140">
            give us a call
          </a>{' '}
          or{' '}
          <a className="font-bold underline" href="mailto:info@epcbuilder.com">
            send us an email
          </a>{' '}
          - we'll be happy to guide you!
        </p>
      </div>
      <div className="w-full sm:w-fit">
        <NewButton
          id="continue-with-selected-plan"
          text="Continue With Selected Plan"
          icon={<MdArrowForward className="ml-2" size="20px" />}
          iconPosition="end"
          alignmentClass="center"
          disabled={!selectedPlanId || processingSelection}
          onClick={() => setPlanDrawerStep(PlanDrawerStep.OrderSummary)}
        />
      </div>
    </div>
  );
};

export default SelectYourPlanStep;
