import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import { FullPage, Loading } from '@epcbuilder/lib/components';
import { InstallationStatus } from '@epcbuilder/lib/models/jobs';
import { handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { useJobContext } from '@/context/useJobContext';
import useInstallationBooking from '@/hooks/installation/useInstallationBooking';
import useJobInstallation from '@/hooks/jobs/useJobInstallation';
import { JobContact } from '@/models/job';
import { patchRequestCallback } from '@/network/jobs';
import { CallUsCard, RequestCallbackCard } from '../ContactCards';
import LearnMoreCard from '../improvement-plan/LearnMoreModal';
import ChosenMeasuresCard from './ChosenMeasuresCard';
import InstallationDetailsCard from './InstallationDetailsCard';
import NextStepsCard from './NextStepsCard';

const InstallationStep = () => {
  const { job } = useJobContext();
  const { jobInstallation } = useJobInstallation({ id: job?.id });
  const { installationBooking } = useInstallationBooking({ id: jobInstallation?.id });

  const isInstallationCompleted = jobInstallation?.installationStatusId === InstallationStatus.INSTALLATION_COMPLETED;
  const handleRequestCallback = useCallback(async () => {
    try {
      await patchRequestCallback({ id: job?.id, type: JobContact.INSTALLATION });
      toast.success(
        'Callback has been requested, someone from the admin team will be in contact to confirm your booking as soon as possible.'
      );
    } catch (error: unknown) {
      handleUnknownDetail(error);
    }
  }, [job]);

  if (!jobInstallation) {
    return (
      <FullPage>
        <Loading />
      </FullPage>
    );
  }

  return (
    <div className="flex items-center justify-center">
      <div className={`grid ${!isInstallationCompleted ? 'gap-6 lg:grid-cols-3 xl:grid-cols-12' : ''} justify-center`}>
        <div
          className={`${!isInstallationCompleted ? 'w-full lg:col-span-3 xl:col-span-8 xl:row-span-3' : 'min-w-none w-full max-w-4xl lg:min-w-[40rem]'}`}
        >
          {installationBooking?.bookingDateTime != null ? (
            <InstallationDetailsCard jobInstallation={jobInstallation} />
          ) : (
            <ChosenMeasuresCard job={job} installationBooking={installationBooking} />
          )}
        </div>
        {!isInstallationCompleted && (
          <>
            <div className="flex flex-row gap-6 lg:order-1 xl:col-span-4 xl:row-span-1">
              <a href="tel:08000584140" className="flex-1">
                <CallUsCard />
              </a>
              <div className="flex-1">
                <RequestCallbackCard onClick={() => handleRequestCallback()} />
              </div>
            </div>
            <div className="flex flex-col lg:order-2 xl:col-span-4 xl:row-span-1">
              <LearnMoreCard
                text="Do you want to learn more ways to reduce your energy bill?"
                url="https://epcbuilder.com/quick-wins"
              />
            </div>
            <div className="flex flex-col lg:order-3 xl:col-span-4 xl:row-span-1">
              <NextStepsCard jobInstallation={jobInstallation} installationBooking={installationBooking} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default InstallationStep;
