import useSWR from 'swr';
import { BankAccountResponse } from '@/models/payments';
import { getCustomerBankAccounts } from '@/network/customers';

const useCustomerBankAccounts = () => {
  const {
    data: bankAccountData,
    error,
    mutate,
    isLoading,
  } = useSWR<BankAccountResponse>('customer-bank-accounts', getCustomerBankAccounts);

  return {
    bankAccountData,
    error,
    isLoading,
    mutate,
  };
};

export default useCustomerBankAccounts;
