import React from 'react';

interface IFormCheckbox {
  label: string;
  value: string;
}

const CheckIcon = () => {
  return (
    <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.70643 0.792787C9.8939 0.980314 9.99922 1.23462 9.99922 1.49979C9.99922 1.76495 9.8939 2.01926 9.70643 2.20679L4.70643 7.20679C4.5189 7.39426 4.2646 7.49957 3.99943 7.49957C3.73427 7.49957 3.47996 7.39426 3.29243 7.20679L0.292431 4.20679C0.110272 4.01818 0.00947813 3.76558 0.0117566 3.50339C0.014035 3.24119 0.119204 2.99038 0.304612 2.80497C0.49002 2.61956 0.740832 2.51439 1.00303 2.51211C1.26523 2.50983 1.51783 2.61063 1.70643 2.79279L3.99943 5.08579L8.29243 0.792787C8.47996 0.605316 8.73427 0.5 8.99943 0.5C9.2646 0.5 9.5189 0.605316 9.70643 0.792787Z"
        fill="white"
      />
    </svg>
  );
};

const FormCheckbox = ({
  onItemSelect,
  itemStyle,
  item,
  selected,
}: {
  onItemSelect: (value: string, isChecked: boolean) => void;
  itemStyle?: 'no-background';
  item: IFormCheckbox;
  selected: boolean;
}) => {
  const labelBackgroundClasses =
    itemStyle === 'no-background'
      ? ''
      : selected
        ? 'bg-blue-dark dark:bg-primary-darker'
        : 'bg-blue-lighter dark:bg-[#195E55]';

  return (
    <label
      className={`${labelBackgroundClasses} flex min-h-10 w-full shrink-0 cursor-pointer items-center gap-2 rounded-[8px] p-2 text-sm duration-200`}
    >
      <input
        type="checkbox"
        className="peer hidden"
        checked={selected}
        onChange={(event) => onItemSelect(item.value, event.target.checked)}
      />
      <div className="border-primary peer-checked:bg-primary peer-checked:dark:border-primary-darker dark:bg-dark-dark flex size-6 shrink-0 items-center justify-center rounded-lg border-2 bg-white align-middle dark:border-[#195E55]">
        {selected && <CheckIcon />}
      </div>
      {item.label}
    </label>
  );
};

const FormCheckboxGroup = ({
  items,
  itemStyle,
  selectedValues,
  onItemSelect,
}: {
  items: IFormCheckbox[];
  itemStyle?: 'no-background';
  selectedValues: string[];
  onItemSelect: (value: string, isChecked: boolean) => void;
}) => {
  return (
    <div className="flex flex-col gap-2">
      {items.map((option, index) => (
        <FormCheckbox
          item={option}
          itemStyle={itemStyle}
          onItemSelect={onItemSelect}
          selected={selectedValues.includes(option.value)}
          key={index}
        />
      ))}
    </div>
  );
};

export default FormCheckboxGroup;
