import useSWR from 'swr';
import { getPostcodeElibilityGroup, getPostcodeLsoa } from '@/network/postcode';

export type PostcodeAttributes = {
  isFlexEligible: boolean;
  isFlexLsoa: boolean;
  isFlexMst: boolean;
};

const usePostcodeAttributes = ({ postcode }: { postcode?: string }): { postcodeAttributes: PostcodeAttributes } => {
  const mstGroups = ['manchester', 'stockport', 'trafford'];
  const sanitizedPostcode = postcode?.replace(' ', '') ?? '';

  const group = useSWR<{ isEligible: boolean; group: string }>(`postcode-group-${sanitizedPostcode}`, async () =>
    getPostcodeElibilityGroup({ postcode: sanitizedPostcode })
  );

  const lsoa = useSWR<boolean>(`postcode-lsoa-${sanitizedPostcode}`, async () =>
    getPostcodeLsoa({ postcode: sanitizedPostcode })
  );

  return {
    postcodeAttributes: {
      isFlexEligible: group.data?.isEligible ?? false,
      isFlexLsoa: lsoa.data ?? false,
      isFlexMst: mstGroups.indexOf(group.data?.group.toLowerCase() ?? '') !== -1,
    },
  };
};

export default usePostcodeAttributes;
