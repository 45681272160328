import React, { useCallback, useEffect, useState } from 'react';
import { FullScreenModal, Loading } from '@epcbuilder/lib/components';
import { PaymentStatus } from '@epcbuilder/lib/models/payments';
import { handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import useGoCardlessCustomer from '@/hooks/customers/useGoCardlessCustomer';
import { postPaymentPoll } from '@/network/payments';
import { deleteSurveyBooking } from '@/network/surveys';
import BookingConfirmation from '../BookingConfirmation';
import OrderSummary from '../OrderSummary';
import PaymentDetailsForm from '../PaymentDetailsForm';
import PersonalDetails from '../PersonalDetails';
import ProgressBar from '../ProgressBar';
import RedirectToGoCardless from '../RedirectToGoCardless';
import PaymentFailedModal from './PaymentFailedModal';

const CardPaymentsModal = ({
  onClose,
  surveyDate,
  surveyBookingId,
  refetchJobSurvey,
}: {
  onClose: () => void;
  surveyDate: string;
  surveyBookingId: string;
  refetchJobSurvey: () => void;
}) => {
  const [step, setStep] = useState(1);
  const [showPaymentFailedModal, setShowPaymentFailedModal] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [goCardlessUrl, setGoCardlessUrl] = useState<string>();
  const [selectedBankAccountId, setSelectedBankAccountId] = useState<string>();
  const { customer, isLoading: isCustomerLoading } = useGoCardlessCustomer();
  const [hasPolled, setHasPolled] = useState(false);

  const goToNextStep = () => setStep((prev) => prev + 1);
  const goToPreviousStep = () => setStep(1);

  const handleCancel = async () => {
    if (surveyBookingId) {
      await deleteSurveyBooking({ id: surveyBookingId });
      refetchJobSurvey();
      onClose();
    }
  };

  const handleCloseOnRetry = async () => {
    setShowPaymentFailedModal(false);
    onClose();
    await deleteSurveyBooking({ id: surveyBookingId });
    window.location.reload();
    refetchJobSurvey();
  };

  const handleClose = () => {
    if (step === 4 || step === 5) {
      onClose();
      refetchJobSurvey();
    } else {
      handleCancel();
    }
  };

  const startPollingPayment = useCallback(async () => {
    if (!surveyBookingId || !customer || hasPolled) return;

    try {
      const response = await postPaymentPoll({ surveyBookingId });
      if (response?.status === 200) {
        const { payment, paymentFound } = response.data;

        if (paymentFound) {
          if ([PaymentStatus.Paid].includes(payment?.status)) {
            setStep(5);
          } else if (
            [
              PaymentStatus.Failed,
              PaymentStatus.Cancelled,
              PaymentStatus.PendingRefund,
              PaymentStatus.Refunded,
              PaymentStatus.ChargedBack,
            ].includes(payment?.status)
          ) {
            setShowPaymentFailedModal(true);
          }
        } else if (paymentFound === false) {
          setStep(1);
        }
      }
    } catch (error) {
      handleUnknownDetail(error);
    } finally {
      setIsInitialLoading(false);
      setHasPolled(true);
    }
  }, [surveyBookingId, customer, hasPolled]);

  useEffect(() => {
    const initialize = async () => {
      if (isCustomerLoading) return;
      if (!customer) {
        setIsInitialLoading(false);
      }
      if (surveyBookingId) {
        await startPollingPayment();
      }
    };

    initialize();
  }, [isCustomerLoading, customer, surveyBookingId, startPollingPayment]);

  const handlePaymentSuccess = () => setStep(5);
  const handlePaymentFailure = () => setShowPaymentFailedModal(true);

  const renderCurrentStep = () => {
    switch (step) {
      case 1:
        return (
          <PersonalDetails
            onNext={goToNextStep}
            layoutClassName={
              'items-left hide-scrollbar flex h-full flex-col overflow-auto px-4 w-full md:px-8 md:ml-[15rem] md:w-[calc(100%-290px)] lg:w-[70%] lg:px-12'
            }
            formClassName="mt-4 flex w-full max-w-2xl flex-col sm:mt-6"
          />
        );
      case 2:
        return (
          <PaymentDetailsForm
            surveyBookingId={surveyBookingId}
            onNext={goToNextStep}
            setGoCardlessUrl={setGoCardlessUrl}
            onBankAccountSelect={setSelectedBankAccountId}
          />
        );
      case 3:
        return (
          customer && (
            <OrderSummary
              onNext={goToNextStep}
              onEditDetails={goToPreviousStep}
              surveyDate={surveyDate}
              surveyBookingId={surveyBookingId}
              customer={customer}
              url={goCardlessUrl}
              selectedBankAccountId={selectedBankAccountId}
              onPaymentSuccess={handlePaymentSuccess}
              onPaymentFailure={handlePaymentFailure}
            />
          )
        );
      case 4:
        return <RedirectToGoCardless url={goCardlessUrl} />;
      case 5:
        return (
          <BookingConfirmation
            onClose={() => {
              onClose();
              refetchJobSurvey();
            }}
          />
        );
      default:
        return <div />;
    }
  };

  return (
    <>
      <FullScreenModal id="card-payments-modal" onClose={handleClose}>
        <ProgressBar currentStep={step} />
        {isInitialLoading ? <Loading id="loading" /> : renderCurrentStep()}
      </FullScreenModal>
      {showPaymentFailedModal && <PaymentFailedModal onClose={handleCloseOnRetry} />}
    </>
  );
};

export default CardPaymentsModal;
