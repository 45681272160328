import React from 'react';
import { useForm } from 'react-hook-form';
import { Checkbox, Loading } from '@epcbuilder/lib/components';
import { NewButton } from '@epcbuilder/lib/components/Buttons';
import { useTheme } from '@epcbuilder/lib/context/themeContext';
import { handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { format } from 'date-fns';
import useCustomerBankAccount from '@/hooks/bankAccounts/useCustomerBankAccount';
import { GoCardlessCustomerResponse } from '@/models/payments';
import { postSurveyBookingNotes } from '@/network/surveys';
import { pollPaymentStatus } from '@/utils/pollPaymentStatus';

type FormData = {
  notes: string;
  confirmChecked: boolean;
  soleAuthChecked: boolean;
};

const DirectDebitLogo = () => {
  const { theme } = useTheme();
  const directDebitLogoLightSrc = '/images/direct-debit-logo.png';
  const directDebitLogoDarkSrc = '/images/direct-debit-logo-white.png';
  const directDebitLogoSrc = theme === 'light' ? directDebitLogoLightSrc : directDebitLogoDarkSrc;

  return <img className="h-[60px]" src={directDebitLogoSrc} alt="Direct Debit Logo" />;
};

const OrderSummary = ({
  onNext,
  onEditDetails,
  surveyDate,
  surveyBookingId,
  customer,
  url,
  selectedBankAccountId,
  onPaymentSuccess,
  onPaymentFailure,
}: {
  onNext: () => void;
  onEditDetails: () => void;
  surveyDate: string;
  surveyBookingId: string;
  customer: GoCardlessCustomerResponse;
  url: string | undefined;
  selectedBankAccountId: string | undefined;
  onPaymentSuccess: () => void;
  onPaymentFailure: () => void;
}) => {
  const { register, handleSubmit, watch } = useForm<FormData>();
  const { bankAccountData, isLoading } = useCustomerBankAccount({ id: selectedBankAccountId });

  const confirmChecked = watch('confirmChecked');
  const soleAuthChecked = watch('soleAuthChecked');

  const customerDetails = {
    ...customer,
    firstName: customer.forename,
    lastName: customer.surname,
  };

  const parseCustomDateRange = (surveyDate: string) => {
    const [datePart, timePart] = surveyDate.split(' - ');
    const [day, month, year] = datePart.split('/').map(Number);
    const startHours = timePart === 'AM' ? 8 : 13;
    const endHours = timePart === 'AM' ? 12 : 17;

    const startDate = new Date(year, month - 1, day, startHours);
    const endDate = new Date(year, month - 1, day, endHours);

    return { startDate, endDate, timePart };
  };

  const formatDate = (surveyDate: string) => {
    const { startDate, endDate, timePart } = parseCustomDateRange(surveyDate);

    return `${format(startDate, "EEEE, d MMM ''yy")} ${timePart} ${format(startDate, 'HH:mm')} - ${format(endDate, "HH:mm 'BST'")}`;
  };

  const onSubmit = async (data: { notes: string }) => {
    try {
      await postSurveyBookingNotes(surveyBookingId, { notes: data.notes });
      window.open(url, '_blank');
      onNext();
      await pollPaymentStatus(surveyBookingId, onPaymentSuccess, onPaymentFailure);
    } catch (error) {
      handleUnknownDetail(error);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  if (customerDetails) {
    return (
      <div className="items-left hide-scrollbar flex h-full flex-col overflow-auto px-4 md:ml-[15rem] md:w-[calc(100%-290px)] md:pl-8 lg:w-[70%] lg:px-12">
        <h1 id="order-summary-heading" className="font-header w-full text-left text-2xl">
          Order Summary
        </h1>
        <form className="mt-6 flex flex-col justify-center gap-2 sm:max-w-2xl xl:gap-4">
          <p className="mb-4 text-justify text-[14px]">
            This payment will be made to Improveasy Ltd, on behalf of EPC Builder, for the reason of conducting a survey
            for potential energy improvements at <span className="font-bold">{customerDetails.address.postCode}</span>.
            The booking reference for this is <span className="font-bold">{surveyBookingId}.</span>
          </p>
          <div>
            <p className="mb-2">{formatDate(surveyDate)}</p>
            <p className="font-bold" id="total-cost">
              Total cost of booking:
              <br />
              £195.00
            </p>
          </div>
          <div className="border-neutral border-b-[1px]"></div>
          <div>
            <p className="mb-2 font-bold" id="bank-details">
              Bank Details:
            </p>
            {bankAccountData && (
              <div className="mb-4">
                <p>
                  Account Holder:{' '}
                  {bankAccountData.accountHolder.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase())}
                </p>
                <p>Sort Code: {bankAccountData.sortCode || 'No details'}</p>
                <p>Account Number: {bankAccountData.accountNumber || 'No details'}</p>
              </div>
            )}
          </div>
          <div className="border-neutral border-b-[1px]"></div>
          <div>
            <p className="mb-2 font-bold" id="billing-address">
              Billing address:
            </p>
            <p>
              {customerDetails.firstName} {customerDetails.lastName}
            </p>
            <p>
              {customerDetails.address.addressLine1}
              {customerDetails.address.addressLine2 && (
                <>
                  <br />
                  {customerDetails.address.addressLine2}
                </>
              )}
              {customerDetails.address.addressLine3 && (
                <>
                  <br />
                  {customerDetails.address.addressLine3}
                </>
              )}
            </p>
            <p>
              {customerDetails.address.postCode}, {customerDetails.address.city}
            </p>
          </div>
          <div className="border-neutral border-b-[1px]"></div>
          <div>
            <p className="mb-2 font-bold" id="contact-details">
              Your contact details:
            </p>
            <p>{customerDetails.phoneNumber}</p>
            <p>{customerDetails.email}</p>
          </div>
          <div className="border-neutral border-b-[1px]"></div>
          <div>
            <p className="mb-2 font-bold">Notes</p>
            <textarea
              placeholder="Is there anything else you'd like us to know? (Optional)"
              {...register('notes')}
              id="notes"
              className="text-color bg-color-secondary border-blue-lighter dark:border-primary-darker focus:border-primary-lighter h-20 w-full rounded-xl border-2 px-4 py-1 text-base outline-none"
            />
          </div>
          <div className="flex">
            <Checkbox id="soleAuthoriseHolder" {...register('soleAuthChecked')} />
            <p className="ml-4">I confirm that I am the sole person required to authorise debits from this account.</p>
          </div>
          <div className="flex">
            <Checkbox id="termsAndConditions" {...register('confirmChecked')} />
            <p className="ml-4">
              I have read and accept the{' '}
              <a
                className="underline hover:opacity-80"
                target="_blank"
                href="https://epcbuilder.com/terms"
                rel="noreferrer"
              >
                Terms & Conditions
              </a>{' '}
              and the{' '}
              <a
                className="underline hover:opacity-80"
                target="_blank"
                href="https://epcbuilder.com/refund"
                rel="noreferrer"
              >
                Refund Policy
              </a>
              .
            </p>
          </div>{' '}
          <div className="mt-2 flex items-center justify-between gap-6 sm:gap-4">
            <p className="flex-1 text-justify text-[14px]">
              EPC Builder uses direct debit to process payments, this ensures customers are protected by the
              <a
                className="underline hover:opacity-80"
                target="_blank"
                href="https://epcbuilder.com/direct-debit-guarantee"
                rel="noreferrer"
              >
                {' '}
                Direct Debit Guarantee
              </a>
              . You may cancel this Direct Debit at any time by contacting the team at EPC Builder or your bank. If you
              would like to get in touch, please call us on{' '}
              <a href="tel:08000584140" className="text-[14px] underline">
                0800 058 4140
              </a>
              .
            </p>
            <div>
              <DirectDebitLogo />
            </div>
          </div>
          <div className="mb-12 mt-4 flex flex-col gap-4 sm:flex-row sm:justify-between">
            <NewButton id="edit-button" variant="secondary" text="Edit Details" onClick={onEditDetails} />
            <NewButton
              id="next-button"
              variant="primary"
              text="Proceed To Payment"
              onClick={handleSubmit(onSubmit)}
              disabled={!confirmChecked || !soleAuthChecked}
            />
          </div>
        </form>
      </div>
    );
  }
};

export default OrderSummary;
