import React from 'react';
import { useFormContext } from 'react-hook-form';
import { NewButton } from '@epcbuilder/lib/components/Buttons';
import { EligibilityFormData, IEligibilityFormNavigation } from './EligibilityModal';
import EligibilityStageContainer from './EligibilityStageContainer';
import FormCheckboxGroup from './FormCheckboxGroup';
import ProgressBar from './ProgressBar';

const flexCriteriaOptions = [
  { label: 'Receive a council tax rebate due to low income', value: 'CouncilTaxRebate' },
  {
    label:
      'A mental health condition, disability, pregnant, aged over 65, a child who has not yet started primary school',
    value: 'NICE',
  },
  { label: 'A child receiving free school meals', value: 'FreeSchoolMeals' },
  {
    label: 'Identified as struggling to pay your energy bills by your provider or Citizens Advice',
    value: 'StrugglingToPay',
  },
  {
    label: 'A cardiovascular condition, respiratory condition, immunosuppression or limited mobility',
    value: 'CriticalIllness',
  },
  { label: 'None of the above', value: 'NoneOfTheAbove' },
];

const EligibilityStageFlexCriteria = ({ forward, back }: IEligibilityFormNavigation) => {
  const { setValue, watch } = useFormContext<EligibilityFormData>();

  const flexCriteria = watch('flexCriteria');

  const onItemSelect = (value: string, isChecked: boolean) => {
    if (value === 'NoneOfTheAbove' && isChecked) {
      setValue('flexCriteria', ['NoneOfTheAbove']);
    } else {
      const newFlexCriteria = isChecked
        ? [...flexCriteria.filter((item) => item !== 'NoneOfTheAbove'), value]
        : [...flexCriteria].filter((item) => item !== value);

      setValue('flexCriteria', newFlexCriteria);
    }
  };

  return (
    <EligibilityStageContainer>
      <ProgressBar progress={2} />
      <h1>Tick all that apply to you or someone in your household:</h1>
      <FormCheckboxGroup items={flexCriteriaOptions} onItemSelect={onItemSelect} selectedValues={flexCriteria} />
      <div className="flex flex-row gap-2">
        <NewButton onClick={() => back()} id="eligibility-back" variant="secondary" text="Back" />
        <NewButton onClick={() => forward()} id="eligibility-next" text="Next" disabled={flexCriteria.length === 0} />
      </div>
    </EligibilityStageContainer>
  );
};

export default EligibilityStageFlexCriteria;
