import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { TextInput } from '@epcbuilder/lib/components';
import { NewButton } from '@epcbuilder/lib/components/Buttons';
import { handleFormErrors } from '@epcbuilder/lib/utils';
import { AxiosErrorData, handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { PHONE_REGEX, POSTCODE_REGEX } from '@epcbuilder/lib/utils/generic';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import useGoCardlessCustomer from '@/hooks/customers/useGoCardlessCustomer';
import { CustomerDetails } from '@/models/payments';

const customerSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(/^[A-Za-z ]*$/, 'Invalid first name')
    .required('First Name must not be empty'),
  lastName: Yup.string()
    .matches(/^[A-Za-z ]*$/, 'Invalid last name')
    .required('Last Name must not be empty'),
  phoneNumber: Yup.string()
    .matches(PHONE_REGEX, 'Phone is not a valid UK phone number')
    .required('Phone must not be empty'),
  email: Yup.string().required('Email must not be empty').email('Invalid email address'),
  addressLine1: Yup.string().required('Address Line 1 must not be empty'),
  addressLine2: Yup.string(),
  addressLine3: Yup.string(),
  city: Yup.string().required('City must not be empty'),
  postcode: Yup.string()
    .required('Postcode must not be empty')
    .matches(POSTCODE_REGEX, 'Postcode is not a valid postcode'),
});

const PersonalDetails = ({
  onNext,
  layoutClassName,
  formClassName,
}: {
  onNext: () => void;
  layoutClassName?: string;
  formClassName?: string;
}) => {
  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm<CustomerDetails>({
    resolver: yupResolver(customerSchema),
  });
  const { customer, updateCustomer } = useGoCardlessCustomer();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (customer) {
      reset({
        firstName: customer.forename || '',
        lastName: customer.surname || '',
        addressLine1: customer.address.addressLine1 || '',
        addressLine2: customer.address.addressLine2 || '',
        addressLine3: customer.address.addressLine3 || '',
        city: customer.address.city || '',
        email: customer.email || '',
        phoneNumber: customer.phoneNumber || '',
        postcode: customer.address.postCode || '',
      });
    }
  }, [customer, reset]);

  const handleFormSubmit: SubmitHandler<CustomerDetails> = async (data) => {
    setLoading(true);
    try {
      const formData = {
        ...data,
        forename: data.firstName,
        surname: data.lastName,
        region: 'UK',
      };
      await updateCustomer(formData);
      onNext();
    } catch (error: unknown) {
      const { errors } = error as AxiosErrorData;
      handleFormErrors<CustomerDetails>(setError, errors);
      handleUnknownDetail(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={layoutClassName}>
      <h1 id="personal-details-heading" className="font-header w-full text-left text-2xl">
        Personal Details
      </h1>
      <form className={formClassName}>
        <div className="relative">
          <div className="mb-4 grid grid-cols-1 gap-4 sm:mb-6 lg:grid-cols-2">
            <TextInput
              {...register('firstName')}
              id="firstName"
              label="First Name"
              placeholder="Enter your first name"
              className="w-full"
              error={errors.firstName?.message}
            />
            <TextInput
              {...register('lastName')}
              id="lastName"
              label="Last Name"
              placeholder="Enter your last name"
              className="w-full"
              error={errors.lastName?.message}
            />
          </div>
          <div className="mb-4 grid grid-cols-1 gap-4 sm:mb-6 lg:grid-cols-2">
            <TextInput
              {...register('phoneNumber')}
              id="phoneNumber"
              label="Phone Number"
              placeholder="Enter your phone number"
              className="w-full"
              error={errors.phoneNumber?.message}
            />
            <TextInput
              {...register('email')}
              id="email"
              label="Email"
              placeholder="Enter your email address"
              className="w-full"
              error={errors.email?.message}
            />
          </div>
          <div className="mb-4 flex flex-col gap-2 sm:mb-6">
            <TextInput
              {...register('addressLine1')}
              id="addressLine1"
              label="Address"
              placeholder="Address Line 1"
              className="w-full"
              error={errors.addressLine1?.message}
            />
            <TextInput
              {...register('addressLine2')}
              id="addressLine2"
              placeholder="Address Line 2 (Optional)"
              className="w-full"
            />
            <TextInput
              {...register('addressLine3')}
              id="addressLine3"
              placeholder="Address Line 3 (Optional)"
              className="w-full"
            />
          </div>
          <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
            <TextInput
              {...register('city')}
              id="city"
              label="City"
              placeholder="Enter your city"
              className="w-full"
              error={errors.city?.message}
            />
            <TextInput
              {...register('postcode')}
              id="postcode"
              label="Postcode"
              placeholder="Enter your postcode"
              className="w-full"
              error={errors.postcode?.message}
            />
          </div>
        </div>
        <div className="mb-10 mt-6 flex justify-center sm:mt-8 lg:mb-2 lg:w-24">
          <NewButton
            id="confirm-button"
            variant="primary"
            text="Next"
            loading={loading}
            onClick={handleSubmit(handleFormSubmit)}
          />
        </div>
      </form>
    </div>
  );
};

export default PersonalDetails;
