import React from 'react';
import { Spinner } from '@epcbuilder/lib/components';

const RedirectToGoCardless = ({ url }: { url: string | undefined }) => {
  return (
    <div className="items-left flex flex-col justify-center px-4 md:ml-[15rem] md:w-[calc(100%-290px)] md:pl-8 lg:w-[70%] lg:px-12">
      <h1 id="redirect-to-gocardless-heading" className="font-header w-full text-left text-2xl">
        Redirect to GoCardless
      </h1>
      <div className="mt-40 flex flex-col items-center justify-center text-center">
        <Spinner color="#2a9c8e" />
        <p className="mt-8 font-bold">Redirecting to GoCardless for payment…</p>
        <p className="mt-6">
          <a className="font-bold underline hover:opacity-80" href={url} target="_blank" rel="noopener noreferrer">
            Click here
          </a>{' '}
          if the payment screen did not open automatically.
        </p>
      </div>
    </div>
  );
};

export default RedirectToGoCardless;
