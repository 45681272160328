import { PostcodeAttributes } from '@/hooks/properties/usePostcodeAttributes';
import { EligibilityFormData } from './eligibility-modal/EligibilityModal';

/**
 * Enum names correspond the "Name" value of records in the "Schemes" table
 */
export enum GrantSchemes {
  BoilerUpgrade = 'Boiler Upgrade Scheme (BUS)',
  GreatBritishInsulation = 'Great British Insulation Scheme (GBIS)',
  EnergyCompanyObligation4 = 'Energy Company Obligation (ECO4)',
  EcoFlex = 'ECO4 LA Flex',
}

type QualifyingCriteriaContext = Omit<EligibilityFormData, 'consent'> & PostcodeAttributes;

const meetsEco4 = (qc: QualifyingCriteriaContext): boolean => {
  if (qc.benefits.length >= 1 && !qc.benefits.includes('noneoftheabove')) {
    return true;
  }

  return false;
};

const meetsEcoFlex = (qc: QualifyingCriteriaContext): boolean => {
  if (!qc.isFlexEligible) {
    return false;
  }

  if (qc.incomeLess31k) {
    return true;
  }

  if (qc.flexCriteria.includes('CriticalIllness')) {
    return true;
  }

  if (qc.flexCriteria.includes('CouncilTaxRebate') && qc.flexCriteria.includes('FreeSchoolMeals')) {
    return true;
  }

  if (qc.flexCriteria.includes('CouncilTaxRebate') && qc.flexCriteria.includes('NICE')) {
    return true;
  }

  if (qc.flexCriteria.includes('FreeSchoolMeals') && qc.flexCriteria.includes('NICE')) {
    return true;
  }

  if (qc.isFlexMst) {
    if (qc.flexCriteria.includes('StrugglingToPay') && qc.flexCriteria.includes('CouncilTaxRebate')) {
      return true;
    }
    if (qc.flexCriteria.includes('StrugglingToPay') && qc.flexCriteria.includes('FreeSchoolMeals')) {
      return true;
    }
    if (qc.flexCriteria.includes('StrugglingToPay') && qc.flexCriteria.includes('NICE')) {
      return true;
    }
  }

  if (qc.isFlexLsoa) {
    if (qc.flexCriteria.includes('CouncilTaxRebate')) {
      return true;
    }
    if (qc.flexCriteria.includes('FreeSchoolMeals')) {
      return true;
    }
  }

  return false;
};

/**
 * Stubbed out while we wait for confirmation on the criteria.
 * https://improveasy.atlassian.net/browse/EPC-1280
 */
const meetsBoilerUpgradeScheme = (qc: QualifyingCriteriaContext): boolean => {
  // CI lint still complaining about unused var despite comment to disable the rule
  // Will always return true for now.
  return qc !== undefined;
};

/**
 * Stubbed out while we wait for confirmation on the criteria.
 * https://improveasy.atlassian.net/browse/EPC-1280
 */
const meetsGreatBritainInsulationScheme = (qc: QualifyingCriteriaContext): boolean => {
  // CI lint still complaining about unused var despite comment to disable the rule.
  // Will always return true for now.
  return qc !== undefined;
};

const qualifyingFunctions: Record<GrantSchemes, (qc: QualifyingCriteriaContext) => boolean> = {
  [GrantSchemes.EcoFlex]: meetsEcoFlex,
  [GrantSchemes.EnergyCompanyObligation4]: meetsEco4,
  [GrantSchemes.BoilerUpgrade]: meetsBoilerUpgradeScheme,
  [GrantSchemes.GreatBritishInsulation]: meetsGreatBritainInsulationScheme,
};

export const doesQualify = (qc: QualifyingCriteriaContext): boolean => {
  return meetsEco4(qc) || meetsEcoFlex(qc);
};

export const findQualifyingSchemes = (qc: QualifyingCriteriaContext): GrantSchemes[] => {
  const schemes: GrantSchemes[] = [];

  Object.entries(qualifyingFunctions).forEach(([scheme, qualifyFn]) => {
    if (qualifyFn(qc)) {
      schemes.push(scheme as GrantSchemes);
    }
  });

  return schemes;
};
