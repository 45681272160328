import { handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { postPaymentPoll } from '@/network/payments';
import { patchSurveyBookingState } from '@/network/surveys';
import { PaymentStatus } from '../../../lib/models/payments';

export async function pollPaymentStatus(
  surveyBookingId: string,
  onPaymentSuccess: () => void,
  onPaymentFailure: () => void
) {
  const initialDelayMs = 15 * 1000; // Initial delay before starting to poll
  const pollingIntervalMs = 5 * 1000; // Interval between polls
  const maxPollingTimeMs = 5 * 60 * 1000; // Max total polling duration (5 minutes)
  const startTime = Date.now();

  // Initial delay
  await new Promise((resolve) => setTimeout(resolve, initialDelayMs));

  while (Date.now() - startTime < maxPollingTimeMs) {
    try {
      const response = await postPaymentPoll({ surveyBookingId });

      if (response?.status === 200) {
        const { payment, paymentFound } = response.data;
        if (paymentFound === true && [PaymentStatus.Paid].includes(payment?.status)) {
          await patchSurveyBookingState(surveyBookingId, { state: 2 });
          onPaymentSuccess();
          return;
        } else if (
          paymentFound === true &&
          [
            PaymentStatus.Failed,
            PaymentStatus.Cancelled,
            PaymentStatus.PendingRefund,
            PaymentStatus.Refunded,
            PaymentStatus.ChargedBack,
          ].includes(payment?.status)
        ) {
          onPaymentFailure();
          return;
        }
      }
    } catch (error) {
      handleUnknownDetail(error);
    }

    await new Promise((resolve) => setTimeout(resolve, pollingIntervalMs));
  }

  // If max polling time reached without resolution, fail
  onPaymentFailure();
}
