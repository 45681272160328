import useSWR from 'swr';
import { JobImprovementDetailsDto } from '@/models/job';
import { getJobImprovements } from '@/network/jobs';

const useJobImprovements = ({ id }: { id?: string }) => {
  const { data, error, mutate, isLoading } = useSWR<JobImprovementDetailsDto[]>(
    id ? `job-improvements-${id}` : null,
    async () => (id ? await getJobImprovements({ id }) : null)
  );

  return {
    jobImprovements: data,
    error,
    mutate,
    isLoading,
  };
};

export default useJobImprovements;
