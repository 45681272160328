import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { FilteredDatePicker, Loading, Modal, NewSelect } from '@epcbuilder/lib/components';
import { NewButton } from '@epcbuilder/lib/components/Buttons';
import { slotOptions } from '@epcbuilder/lib/models/surveyBookingOptions';
import { handleFormErrors } from '@epcbuilder/lib/utils';
import { AxiosErrorData, handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import useSurveyBookingAvailableSlots from '@/hooks/surveys/useSurveyBookingAvailableSlots';
import { SurveyBooking } from '@/models/job';
import { patchSurveyBooking } from '@/network/surveys';

const RescheduleSurveyModal = ({
  onClose,
  surveyBooking,
  refetchJobSurvey,
}: {
  onClose: () => void;
  surveyBooking: SurveyBooking | undefined;
  refetchJobSurvey: () => void;
}) => {
  const defaultValues = {
    surveyDate: surveyBooking?.surveyDate ? new Date(surveyBooking?.surveyDate) : undefined,
    bookingSlot: surveyBooking?.bookingSlot,
    notes: surveyBooking?.notes || '',
  };

  const {
    register,
    setError,
    setValue,
    handleSubmit,
    control,
    watch,
    formState: { isSubmitting },
  } = useForm<SurveyBooking>({ defaultValues });

  const onSubmit: SubmitHandler<SurveyBooking> = async (data) => {
    try {
      if (surveyBooking) {
        await patchSurveyBooking(surveyBooking?.id, data);
        refetchJobSurvey();
      }
      toast.success('Survey booking updated', { toastId: 'survey-booking-success' });
      onClose();
    } catch (error: unknown) {
      const { errors } = error as AxiosErrorData;
      handleFormErrors<SurveyBooking>(setError, errors);
      handleUnknownDetail(error);
    }
  };

  const today = new Date();
  today.setHours(0, 0, 0);

  const maxDate = new Date();
  maxDate.setDate(today.getDate() + 92);
  maxDate.setHours(0, 0, 0);

  const { availableSlots: slotAvailability } = useSurveyBookingAvailableSlots({
    start: today,
    end: maxDate,
  });

  const availableDays = slotAvailability?.filter((x) => x.available).map((x) => new Date(x.date)) || [];

  if (
    surveyBooking?.surveyDate &&
    !availableDays.some((d) => d.getTime() === new Date(surveyBooking?.surveyDate).getTime())
  ) {
    availableDays.push(new Date(surveyBooking?.surveyDate));
  }

  const daysToRemove = slotAvailability
    ?.filter((x) => !availableDays.some((d) => d.getTime() === new Date(x.date).getTime()))
    .map((x) => new Date(x.date));

  const [availableSlots, setAvailableSlots] = useState(slotOptions);
  const surveyDate = watch('surveyDate');

  useEffect(() => {
    const specificDate = slotAvailability?.filter((x) => new Date(x.date).getDate() === surveyDate?.getDate());
    const am = specificDate?.find((x) => x.slot === 1);
    const pm = specificDate?.find((x) => x.slot === 2);

    setAvailableSlots(slotOptions);

    if ((!am || !am.available) && (surveyBooking?.surveyDate !== surveyDate || surveyBooking?.bookingSlot !== 1)) {
      setAvailableSlots(slotOptions.filter((x) => x.label === 'PM'));
      setValue('bookingSlot', 2);
    }
    if ((!pm || !pm.available) && (surveyBooking?.surveyDate !== surveyDate || surveyBooking?.bookingSlot !== 2)) {
      setAvailableSlots(slotOptions.filter((x) => x.label === 'AM'));
      setValue('bookingSlot', 1);
    }
  }, [surveyDate, slotAvailability, setValue, surveyBooking?.bookingSlot, surveyBooking?.surveyDate]);

  if (isSubmitting) {
    return <Loading />;
  }

  return (
    <Modal id="reschedule-survey-modal" onClose={onClose}>
      <h1>Edit Survey Details</h1>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-6 flex flex-col gap-6">
        <div className="flex w-full gap-4">
          <div className="w-full">
            <FilteredDatePicker
              daysToRemove={daysToRemove || []}
              maxDate={maxDate}
              id="surveyDate"
              control={control}
              name="surveyDate"
              label="Select New Date"
              icon
            />
          </div>
          <div className="w-full">
            <NewSelect
              control={control}
              id="bookingSlot"
              name="bookingSlot"
              title="Select Time Slot"
              label="Select Time Slot"
              className="mt-2 rounded-xl"
              options={availableSlots}
            />
          </div>
        </div>
        <div>
          <p className="mb-2 font-bold">Notes</p>
          <textarea
            placeholder="Is there anything else you'd like us to know?"
            {...register('notes')}
            id="notes"
            className="text-color bg-color-secondary border-blue-lighter dark:border-primary-darker focus:border-primary-lighter h-20 w-full rounded-xl border-2 px-4 py-1 text-base outline-none"
          />
        </div>
        <div className="mt-4 flex flex-row items-center justify-between gap-4">
          <NewButton id="cancel-button" variant="secondary" onClick={onClose} text="Cancel" />
          <NewButton
            id="access-submit"
            loading={isSubmitting}
            variant="primary"
            text="Submit"
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      </form>
    </Modal>
  );
};

export default RescheduleSurveyModal;
