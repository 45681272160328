import React from 'react';
import { FadeLoader } from 'react-spinners';

const Spinner = ({ id, color, className }: { id?: string; color?: string; className?: string }) => {
  return (
    <div id={id} className={`flex h-16 flex-row items-center justify-center ${className}`}>
      <FadeLoader color={color || '#2a9c8e'} />
    </div>
  );
};

export default Spinner;
