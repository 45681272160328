import React, { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { TextInput } from '@epcbuilder/lib/components';
import { NewButton } from '@epcbuilder/lib/components/Buttons';
import { Company } from '@epcbuilder/lib/models/companies';
import { EMAIL_REGEX, handleFormErrors } from '@epcbuilder/lib/utils';
import { AxiosErrorData, handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { PHONE_REGEX } from '@epcbuilder/lib/utils/generic';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import useUser from '@/hooks/auth/useUser';
import { Profile } from '@/models/user';
import { putProfile } from '@/network/profile';

const profileSchema = yup.object().shape({
  email: yup.string().matches(EMAIL_REGEX, 'Email is not a valid email address').required('Email must not be empty'),
  firstName: yup.string().required('First Name must not be empty'),
  lastName: yup.string().required('Last Name must not be empty'),
  phone: yup.string().matches(PHONE_REGEX, 'Phone is not a valid UK phone number').required('Phone must not be empty'),
  secondaryPhone: yup
    .string()
    .matches(PHONE_REGEX, { message: 'Secondary phone is not a valid UK phone number', excludeEmptyString: true })
    .nullable(),
});

const ProfileForm = ({ user, company, id }: { user: Profile; company: Company | undefined; id: string }) => {
  const { user: profileData } = useUser();

  const defaultValues: Profile = profileData || {
    email: user?.email || '',
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    phone: user?.phone || '',
    secondaryPhone: user?.secondaryPhone || '',
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    reset,
  } = useForm<Profile>({
    defaultValues,
    resolver: yupResolver(profileSchema),
    reValidateMode: 'onSubmit',
  });

  useEffect(() => {
    if (profileData) {
      reset(profileData);
    }
  }, [profileData, reset]);

  const onSubmit: SubmitHandler<Profile> = async (data) => {
    try {
      await putProfile({
        email: data.email.trim(),
        firstName: data.firstName,
        lastName: data.lastName,
        phone: data.phone.trim(),
        secondaryPhone: data.secondaryPhone?.trim() || null,
      });
      toast.success('Profile successfully updated', { toastId: 'profile-success' });
    } catch (error: unknown) {
      const { errors } = error as AxiosErrorData;
      handleFormErrors<Profile>(setError, errors);
      handleUnknownDetail(error);
    }
  };

  return (
    <div id={id} className="flex flex-col">
      <div className="border-neutral border-b text-start dark:border-[#516B75]">
        <h1 className="mb-2 font-semibold uppercase sm:font-bold">User Profile</h1>
        <p className="pb-4 font-[14px] sm:font-[18px]">Add or edit information about yourself.</p>
      </div>
      <form id="profile-form" className="flex w-full flex-col gap-4 pt-6">
        <TextInput
          {...register('firstName')}
          id="firstName"
          name="firstName"
          title="Your first name"
          label="First Name"
          placeholder="First name"
          error={errors.firstName?.message}
        />
        <TextInput
          {...register('lastName')}
          id="lastName"
          name="lastName"
          title="Your last name"
          label="Last Name"
          placeholder="Last name"
          error={errors.lastName?.message}
        />
        <TextInput
          readOnly
          {...register('email')}
          id="email"
          name="email"
          title="Your email address"
          label="Email"
          placeholder="Email"
          error={errors.email?.message}
        />
        <TextInput
          {...register('phone')}
          id="phone"
          name="phone"
          title="Your phone number"
          label="Phone"
          placeholder="Phone"
          error={errors.phone?.message}
        />
        <TextInput
          {...register('secondaryPhone')}
          id="secondaryPhone"
          name="secondaryPhone"
          title="Your secondary phone number"
          label="Secondary Phone"
          placeholder="Secondary Phone (optional)"
          error={errors.secondaryPhone?.message}
        />
        {company?.companyName && (
          <TextInput
            readOnly
            id="company"
            name="company"
            title="Your company"
            label="Company"
            value={company.companyName}
          />
        )}
        <div className="w-full sm:w-36">
          <NewButton
            id="profile-submit"
            loading={isSubmitting}
            type="submit"
            text="Save"
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      </form>
    </div>
  );
};

export default ProfileForm;
