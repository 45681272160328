import React from 'react';
import { NewButton } from '@epcbuilder/lib/components/Buttons';
import useUser from '@/hooks/auth/useUser';

const BookingConfirmation = ({ onClose }: { onClose: () => void }) => {
  const { user } = useUser();
  return (
    <div className="items-left flex flex-col justify-center px-4 md:ml-[15rem] md:w-[calc(100%-290px)] md:pl-8 lg:w-[70%] lg:px-12">
      <h1 id="booking-confirmation-heading" className="font-header mb-6 w-full text-left text-2xl">
        Booking Confirmation
      </h1>
      <div className="max-w-2xl">
        <p>
          Booking confirmed! Thank you for booking your EPC survey. You will receive a confirmation email to
          <span className="font-bold"> {user?.email}</span> within 3 working days.
        </p>
        <div className="mt-6 w-full sm:w-52">
          <NewButton id="view-booking-details-button" text="View Booking details" variant="primary" onClick={onClose} />
        </div>
      </div>
    </div>
  );
};

export default BookingConfirmation;
