import React from 'react';
import { SimpleProgressBar } from '@epcbuilder/lib/components';

type ProgressValue = 1 | 2 | 3;

interface IProgressBarProps {
  progress: ProgressValue;
}

const Spacer = ({ faded }: { faded?: boolean }) => {
  const bgClass = faded ? 'dark:bg-[#195E55] bg-blue-dark' : 'bg-primary';
  return <div className={`h-[2px] w-full ${bgClass}`}></div>;
};

const TickedStageIcon = () => {
  return (
    <>
      <svg
        className="block shrink-0 dark:hidden"
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
      >
        <circle cx="12.5" cy="12" r="12" fill="#2A9C8E" />
        <path
          d="M17.1266 7.54456L10.4839 14.1873L6.8652 10.5786L5.44392 11.9999L10.4839 17.0399L18.5479 8.97592L17.1266 7.54456ZM12.4999 1.91992C6.93576 1.91992 2.41992 6.43576 2.41992 11.9999C2.41992 17.5641 6.93576 22.0799 12.4999 22.0799C18.0641 22.0799 22.5799 17.5641 22.5799 11.9999C22.5799 6.43576 18.0641 1.91992 12.4999 1.91992ZM12.4999 20.0639C8.04456 20.0639 4.43592 16.4553 4.43592 11.9999C4.43592 7.54456 8.04456 3.93592 12.4999 3.93592C16.9553 3.93592 20.5639 7.54456 20.5639 11.9999C20.5639 16.4553 16.9553 20.0639 12.4999 20.0639Z"
          fill="white"
        />
      </svg>
      <svg
        className="hidden shrink-0 dark:block"
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
      >
        <circle cx="12.5" cy="12" r="12" fill="#2A9C8E" />
        <path
          d="M17.1266 7.54456L10.4839 14.1873L6.8652 10.5786L5.44392 11.9999L10.4839 17.0399L18.5479 8.97592L17.1266 7.54456ZM12.4999 1.91992C6.93576 1.91992 2.41992 6.43576 2.41992 11.9999C2.41992 17.5641 6.93576 22.0799 12.4999 22.0799C18.0641 22.0799 22.5799 17.5641 22.5799 11.9999C22.5799 6.43576 18.0641 1.91992 12.4999 1.91992ZM12.4999 20.0639C8.04456 20.0639 4.43592 16.4553 4.43592 11.9999C4.43592 7.54456 8.04456 3.93592 12.4999 3.93592C16.9553 3.93592 20.5639 7.54456 20.5639 11.9999C20.5639 16.4553 16.9553 20.0639 12.4999 20.0639Z"
          fill="#153542"
        />
      </svg>
    </>
  );
};

const StageIcon = ({ forStage, currentStage }: { forStage: ProgressValue; currentStage: ProgressValue }) => {
  const baseClass = 'shrink-0 flex h-6 w-6 items-center justify-center rounded-full';
  const bgClass = forStage === currentStage ? 'dark:bg-primary bg-primary-darker' : 'dark:bg-[#195E55] bg-blue-dark';
  const textClass = 'text-white';

  if (forStage < currentStage) {
    return <TickedStageIcon />;
  } else {
    return (
      <div className={`${baseClass} ${bgClass} ${textClass}`}>
        <p className="text-xs font-bold">{forStage}</p>
      </div>
    );
  }
};

const LargeProgressBar = ({ progress }: IProgressBarProps) => {
  return (
    <div className="hidden flex-row items-center gap-6 md:flex">
      <StageIcon forStage={1} currentStage={progress} />
      <Spacer faded={progress < 2} />
      <StageIcon forStage={2} currentStage={progress} />
      <Spacer faded={progress < 3} />
      <StageIcon forStage={3} currentStage={progress} />
    </div>
  );
};

const ProgressBar = ({ progress }: IProgressBarProps) => {
  return (
    <>
      <LargeProgressBar progress={progress} />
      <SimpleProgressBar progress={progress * 25} className="md:hidden" />
    </>
  );
};

export default ProgressBar;
