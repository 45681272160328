import React, { Dispatch, SetStateAction } from 'react';
import { MdOutlineEdit } from 'react-icons/md';
import { Card } from '@epcbuilder/lib/components';
import { NewButton } from '@epcbuilder/lib/components/Buttons';
import { useJobContext } from '@/context/useJobContext';
import { usePropertyContext } from '@/context/usePropertyContext';

const TenantDetailsCard = ({
  setShowUpdateAccessDetailsModal,
  setProceedToEmailTenantModal,
  landlordManaged,
}: {
  setShowUpdateAccessDetailsModal: Dispatch<SetStateAction<boolean>>;
  setProceedToEmailTenantModal: Dispatch<SetStateAction<boolean>>;
  landlordManaged: boolean;
}) => {
  const { property } = usePropertyContext();
  const { job } = useJobContext();

  return (
    <Card tooltipId="tooltip-access-details" className="flex items-center justify-center">
      <div className="relative flex flex-col gap-2 lg:gap-8 xl:gap-2">
        <p className="font-bold lg:w-full lg:p-0">{landlordManaged ? 'Tenant details' : 'Access details'}</p>

        <p>If someone else will be giving access to the Assessor, please enter their details here.</p>
        <div className="flex flex-col gap-2">
          <div className="flex flex-col gap-2">
            <div className="flex flex-1 flex-col">
              <p className="flex-1 font-bold">Name:</p>
              <p id="access-name" className="flex-[2]">
                {property?.accessDetails.firstName !== null && property?.accessDetails.lastName !== null
                  ? `${property?.accessDetails.firstName} ${property?.accessDetails.lastName}`
                  : `Not provided`}
              </p>
            </div>
            <div className="flex flex-1 flex-col">
              <p className="flex-1 font-bold">Phone:</p>
              <p id="access-phone" className="flex-[2]">
                {property?.accessDetails.phone !== null ? property?.accessDetails.phone : 'Not provided'}
              </p>
            </div>
          </div>
          <div className="flex flex-col">
            <p className="flex-1 font-bold">Email:</p>
            <div className="flex-[2] truncate">
              <p id="access-email" className="max-w-[80%] truncate">
                {property?.accessDetails.email !== null ? property?.accessDetails.email : 'Not provided'}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full lg:mt-4 xl:mt-0">
        <NewButton
          disabled={!job?.active}
          id="edit-tenant-details"
          onClick={() => {
            setProceedToEmailTenantModal(false);
            setShowUpdateAccessDetailsModal(true);
          }}
          variant="secondary"
          text="Edit Details"
          icon={<MdOutlineEdit size={20} title="test" />}
        />
      </div>
    </Card>
  );
};

export default TenantDetailsCard;
