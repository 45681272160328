import { handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import useSWR from 'swr';
import { CustomerDetails, GoCardlessCustomerResponse } from '@/models/payments';
import { getCustomer, patchCustomer, postCustomer } from '@/network/customers';

const useGoCardlessCustomer = () => {
  const { data, error, mutate, isLoading } = useSWR<GoCardlessCustomerResponse>('go-cardless-customer', getCustomer);

  const updateCustomer = async (customerDetails: CustomerDetails) => {
    try {
      if (data) {
        mutate(
          async () => {
            const res = await patchCustomer(customerDetails);
            return res?.data as GoCardlessCustomerResponse;
          },
          { revalidate: false }
        );
      } else {
        mutate(
          async () => {
            const res = await postCustomer(customerDetails);
            return res?.data as GoCardlessCustomerResponse;
          },
          { revalidate: false }
        );
      }
    } catch (err) {
      handleUnknownDetail(err);
    }
  };
  return {
    customer: data,
    error,
    mutate,
    isLoading,
    updateCustomer,
  };
};

export default useGoCardlessCustomer;
