import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Loading, Modal } from '@epcbuilder/lib/components';
import { useJobContext } from '@/context/useJobContext';
import { useJobImprovementsContext } from '@/context/useJobImprovementsContext';
import { EpcWizardSteps } from '@/models/job';
import FinanceServiceStep from './FinanceServiceStep';
import OrderSummaryStep from './OrderSummaryStep';
import SelectYourPlanStep from './SelectYourPlanStep';

export enum PlanDrawerStep {
  SelectYourPlan,
  OrderSummary,
  FinanceService,
}

interface IImprovementPlansDrawerProps {
  setShowPlansDrawer: Dispatch<SetStateAction<boolean>>;
  setActiveWizardStep: Dispatch<SetStateAction<EpcWizardSteps>>;
}

const ImprovementPlansDrawer = ({ setShowPlansDrawer, setActiveWizardStep }: IImprovementPlansDrawerProps) => {
  const [planDrawerStep, setPlanDrawerStep] = useState<PlanDrawerStep>(PlanDrawerStep.SelectYourPlan);
  const [selectedPlanId, setSelectedPlanId] = useState<number | undefined>(undefined);

  const { job } = useJobContext();
  const {
    jobImprovements,
    error: improvementsError,
    isLoading: improvementsLoading,
    mutate: refetchImprovements,
  } = useJobImprovementsContext();

  const closeDrawer = () => setShowPlansDrawer(false);

  useEffect(() => {
    refetchImprovements();
  }, [refetchImprovements, selectedPlanId]);

  useEffect(() => {
    setSelectedPlanId(jobImprovements?.find((x) => x.isSelected)?.improvementPlanId);
  }, [jobImprovements]);

  return (
    <Modal
      id="improvement-plans-drawer"
      onClose={closeDrawer}
      containerClassName="!items-end"
      className="dark:bg-dark hide-scrollbar !h-[100vh] !max-h-full  w-[100vw] !max-w-full !rounded-none px-4 py-32 sm:px-8 sm:py-16 md:!h-[95vh] md:!rounded-t-[20px] md:px-6"
      closeButtonClassName="top-4 right-4 p-0"
    >
      <div className="flex size-fit justify-start self-center">
        {improvementsLoading && <Loading />}

        {improvementsError ? (
          <p className="text-error dark:text-error-light">{`Error getting improvement plans for job ${job?.id}`}</p>
        ) : null}

        {!improvementsLoading &&
          !improvementsError &&
          {
            [PlanDrawerStep.SelectYourPlan]: (
              <SelectYourPlanStep
                setPlanDrawerStep={setPlanDrawerStep}
                selectedPlanId={selectedPlanId}
                setSelectedPlanId={setSelectedPlanId}
                improvementPlans={jobImprovements}
              />
            ),
            [PlanDrawerStep.OrderSummary]: (
              <OrderSummaryStep
                setPlanDrawerStep={setPlanDrawerStep}
                closeDrawer={closeDrawer}
                setActiveWizardStep={setActiveWizardStep}
                selectedPlan={jobImprovements?.find((x) => x.improvementPlanId === selectedPlanId)}
              />
            ),
            [PlanDrawerStep.FinanceService]: (
              <FinanceServiceStep
                setPlanDrawerStep={setPlanDrawerStep}
                setActiveWizardStep={setActiveWizardStep}
                closeDrawer={closeDrawer}
                selectedPlan={jobImprovements?.find((x) => x.improvementPlanId === selectedPlanId)}
              />
            ),
          }[planDrawerStep]}
      </div>
    </Modal>
  );
};

export default ImprovementPlansDrawer;
