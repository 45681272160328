import useSWR from 'swr';
import { getPayments } from '@/network/payments';

export const usePayments = () => {
  const { data, error, isLoading, mutate } = useSWR('/payment', getPayments);

  return {
    payments: data,
    isLoading,
    isError: error,
    mutate,
  };
};
