import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Property } from '@epcbuilder/lib/models/properties';
import {
  PropertyAddressData,
  PropertyCreatedOnData,
  PropertyCurrentEpcData,
  PropertyLastActivityData,
  PropertyPostcodeData,
  PropertyStatusData,
} from '../properties/PropertyData';

const JobHistoryRow = ({ property }: { property: Property; refetch: () => void }) => {
  const navigate = useNavigate();
  const navigateToProperty = () => {
    navigate(`/job-history/${property.jobId}`);
  };
  const navigateToProperyKeyPress = (event: { key: string }) => {
    if (event.key === 'Enter') {
      navigateToProperty();
    }
  };
  return (
    <>
      <div
        id={property.id}
        className="bg-color shadow-grey-sm dark:shadow-dark-sm dark:hover:shadow-brand-sm hover:shadow-brand-sm my-4 flex w-full flex-row items-center rounded-3xl duration-100"
      >
        <div className="w-[1rem]"></div>
        <div
          className="flex h-20 w-full flex-row"
          tabIndex={0}
          onClick={navigateToProperty}
          onKeyDown={navigateToProperyKeyPress}
          role="button"
        >
          <div className="flex w-full flex-row items-center justify-between">
            <PropertyAddressData property={property} className="ml-4 w-[20rem] md:w-[15rem] xl:w-[20rem]" />
            <PropertyPostcodeData property={property} className="xs:w-[10rem] sm:w-[8rem]" />
            <PropertyStatusData property={property} className="hidden w-[8rem] xl:flex" />
            <PropertyCurrentEpcData property={property} className="hidden w-[8rem] md:flex" />
            <PropertyLastActivityData property={property} className="hidden w-[8rem] lg:flex" />
            <PropertyCreatedOnData property={property} className="mr-4 hidden w-[8rem] lg:flex" />
          </div>
        </div>
      </div>
    </>
  );
};

export default JobHistoryRow;
