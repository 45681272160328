import React, { useState } from 'react';
import { MdLightbulbOutline } from 'react-icons/md';
import { NewButton } from '@epcbuilder/lib/components/Buttons';
import WhatToExpectModal from './modals/WhatToExpectModal';

const FindOutMoreCard = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  return (
    <>
      <div className="bg-light-dark dark:bg-dark-dark dark:shadow-dark-sm shadow-grey-sm relative flex size-full cursor-default flex-col gap-4 rounded-[22px] p-6 text-center">
        <div className="flex w-full flex-row items-center justify-between">
          <p className="text-left font-bold">
            Are you ready for your survey? <br />
            Find out what to expect.
          </p>
          <MdLightbulbOutline className="text-color-brand" size={46} />
        </div>
        <NewButton
          id="find-out-more-button"
          text="Find Out More"
          variant="secondary"
          onClick={() => setShowModal(true)}
        />
      </div>
      {showModal && <WhatToExpectModal onClose={() => setShowModal(false)} />}
    </>
  );
};

export default FindOutMoreCard;
