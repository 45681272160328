import React from 'react';
import { Loading } from '@epcbuilder/lib/components';
import { PaymentModel, PaymentStatus } from '@epcbuilder/lib/models/payments';
import { usePayments } from '@/hooks/payments/usePayments';

const PaymentMethods = ({ id }: { id: string }) => {
  const { payments, isLoading } = usePayments();

  if (isLoading) {
    return <Loading />;
  }

  const formatDate = (date: Date) => {
    const formatDate = new Date(date);
    return formatDate.toLocaleDateString('en-GB', { month: 'short', day: 'numeric', year: 'numeric' });
  };

  const getPaymentStatusLabel = (status: number) => {
    return PaymentStatus[status] || 'Unknown';
  };

  return (
    <div id={id}>
      <div className="border-neutral border-b text-start dark:border-[#516B75]">
        <h1 className="mb-2 font-semibold uppercase sm:font-bold">Payment History</h1>
        <p className="pb-4 font-[14px] sm:font-[18px]">Securely store and manage your preferred payment options.</p>
      </div>
      {payments && payments.length > 0 && (
        <div className="mt-4 hidden lg:flex">
          <div className="w-full">
            <div className="flex justify-between">
              <div className="flex w-[10rem] justify-start md:w-[13rem] xl:w-[19rem]">
                <p className="text-[12px] font-bold uppercase"></p>
              </div>
              <div className="flex w-[8rem] justify-center">
                <p className="text-[12px] font-bold uppercase">Amount</p>
              </div>
              <div className="flex w-[10rem] justify-center">
                <p className="text-[12px] font-bold uppercase">Date</p>
              </div>
              <div className="hidden w-[6rem] justify-center md:flex">
                <p className="text-[12px] font-bold uppercase">Status</p>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="hide-scrollbar h-full overflow-auto px-2 sm:max-h-[570px]">
        {payments && payments.length > 0 ? (
          payments.map((payment: PaymentModel) => (
            <div
              key={payment.paymentId}
              className="bg-color shadow-grey-sm dark:shadow-dark-sm my-4 flex min-h-20 w-full flex-row items-center rounded-[20px] duration-100"
            >
              <div className="flex w-full flex-col justify-between p-4 md:flex-row md:items-center">
                <div className="sm:w-[15rem]  md:w-[10rem] xl:w-[15rem]">
                  <p className="font-bold">{payment.description}</p>
                  <p className="block text-[#516B75] md:hidden dark:text-[#DCE1E3]">
                    £{(payment.amount / 100).toFixed(2)}
                  </p>
                </div>
                <div className="hidden md:block">
                  <p className="text-[#516B75] dark:text-[#DCE1E3]">£{(payment.amount / 100).toFixed(2)}</p>
                </div>
                <div>
                  <p className="w-full text-[#516B75] md:w-[2rem] lg:w-full dark:text-[#DCE1E3]">
                    {formatDate(payment.chargeDate)}
                  </p>
                </div>
                <div>
                  <p className="text-[#516B75] dark:text-[#DCE1E3]">{getPaymentStatusLabel(payment.status)}</p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="mt-6 text-[#516B75] dark:text-[#DCE1E3]">
            You haven’t made any payments yet. Once you complete a transaction, it will appear here.
          </p>
        )}
      </div>
    </div>
  );
};

export default PaymentMethods;
