import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FullScreenModal, Spinner } from '@epcbuilder/lib/components';
import { handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { useJobContext } from '@/context/useJobContext';
import { usePropertyContext } from '@/context/usePropertyContext';
import usePostcodeAttributes from '@/hooks/properties/usePostcodeAttributes';
import { postJobEligibility } from '@/network/jobs';
import { findQualifyingSchemes } from '../ecoFlexQualifyingChecker';
import EligibilityStageBenefits from './EligibilityStageBenefits';
import EligibilityStageFlexCriteria from './EligibilityStageFlexCriteria';
import EligibilityStageHouseholdIncome from './EligibilityStageHouseholdIncome';
import EligibilityStageIntroduction from './EligibilityStageIntroduction';
import EligibilityStageResults from './EligibilityStageResults';

enum EligibilityFormStages {
  Intro = 1,
  HouseholdIncome = 2,
  FlexCriteria = 3,
  Benefits = 4,
  Results = 5,
}

export interface IEligibilityFormNavigation {
  forward: () => void;
  back: () => void;
}

export type EligibilityFormData = {
  consent: boolean;
  incomeLess31k: boolean;
  benefits: string[];
  flexCriteria: string[];
};

const EligibilityModal = ({
  onClose,
  refetchJobEligibilityStatus,
}: {
  onClose: () => void;
  refetchJobEligibilityStatus: () => void;
}) => {
  const [currentStage, setCurrentStage] = useState<EligibilityFormStages>(EligibilityFormStages.Intro);
  const [formHasBeenSubmitted, setFormHasBeenSubmitted] = useState<boolean>(false);
  const formMethods = useForm<EligibilityFormData>({
    defaultValues: {
      incomeLess31k: undefined,
      consent: false,
      flexCriteria: [],
      benefits: [],
    },
  });
  const formData = formMethods.watch();

  const { job } = useJobContext();
  const { property } = usePropertyContext();
  const { postcodeAttributes } = usePostcodeAttributes({ postcode: property?.postcode });

  const eligibleFor = useMemo(() => {
    return findQualifyingSchemes({
      ...formData,
      ...postcodeAttributes,
    });
  }, [formData, postcodeAttributes]);

  const formNavigation = {
    forward: () => setCurrentStage((stage) => stage + 1),
    back: () => setCurrentStage((stage) => stage - 1),
  };

  useEffect(() => {
    if (!formHasBeenSubmitted && currentStage === EligibilityFormStages.Results) {
      try {
        postJobEligibility({
          id: job?.id,
          data: {
            marketingConsent: formData.consent,
            datamatchConsent: formData.consent,
            eligible: eligibleFor.length > 0,
            eligibleSchemes: eligibleFor,
            benefits: formData.benefits,
            flexCriteria: formData.flexCriteria,
            incomeLess31k: formData.incomeLess31k,
          },
        }).then(() => {
          setFormHasBeenSubmitted(true);
          refetchJobEligibilityStatus();
        });
      } catch (error: unknown) {
        handleUnknownDetail(error);
      }
    }
  }, [formData, eligibleFor, currentStage, formHasBeenSubmitted, job?.id, refetchJobEligibilityStatus]);

  if (!job || !property) {
    return (
      <FullScreenModal onClose={onClose} backgroundClasses="dark:bg-dark-dark bg-[#F8F9FA]">
        <div className="flex h-full flex-col items-center justify-center">
          <Spinner />
        </div>
      </FullScreenModal>
    );
  }

  return (
    <FullScreenModal onClose={onClose} backgroundClasses="dark:bg-dark-dark bg-[#F8F9FA]">
      <FormProvider {...formMethods}>
        <form className="flex w-full max-w-[540px] flex-col gap-4">
          {
            {
              [EligibilityFormStages.Intro]: <EligibilityStageIntroduction {...formNavigation} />,
              [EligibilityFormStages.HouseholdIncome]: <EligibilityStageHouseholdIncome {...formNavigation} />,
              [EligibilityFormStages.FlexCriteria]: <EligibilityStageFlexCriteria {...formNavigation} />,
              [EligibilityFormStages.Benefits]: <EligibilityStageBenefits {...formNavigation} />,
              [EligibilityFormStages.Results]: (
                <EligibilityStageResults eligibleFor={eligibleFor} onContinue={onClose} />
              ),
            }[currentStage]
          }
        </form>
      </FormProvider>
    </FullScreenModal>
  );
};

export default EligibilityModal;
