import React from 'react';
import { Children } from '@epcbuilder/lib/utils';

interface IProps {
  title?: string;
  children: Children;
}

const EligibilityStageContainer = ({ title, children }: IProps) => {
  return (
    <div className="hide-scrollbar mx-4 mb-12 flex h-full flex-col justify-center gap-4 overflow-auto text-[#264653] dark:text-white">
      {title && <h1 className="font-header text-lg">{title}</h1>}
      {children}
    </div>
  );
};

export default EligibilityStageContainer;
