import React from 'react';
import { MdClose } from 'react-icons/md';
import { Children } from '../utils';

interface IProps {
  onClose: () => void;
  id?: string;
  children: Children;
  backgroundClasses?: string;
}

const FullScreenModal = ({ onClose, id, children, backgroundClasses }: IProps) => {
  const finalBackgroundClasses = backgroundClasses || 'dark:bg-dark bg-[#F8F9FA]';

  return (
    <div>
      <div className="top fixed inset-0 top-auto z-50 flex h-full cursor-default sm:size-full md:top-auto md:h-[95%] md:w-full">
        <button
          type="button"
          className="bg-dark fixed inset-0 flex border-s-[20px] opacity-[0.56] dark:bg-[#DCE1E3]"
          onClick={onClose}
        />
        <div
          id={id || 'full-screen-modal'}
          className={`${finalBackgroundClasses} relative flex size-auto w-full flex-col items-center overflow-y-auto shadow md:w-screen md:rounded-t-xl`}
        >
          <div className="sticky left-0 top-0 z-10 w-full bg-inherit">
            <button
              type="button"
              className={`${finalBackgroundClasses} text-color flex w-full cursor-pointer justify-end p-4`}
              onClick={onClose}
            >
              <MdClose size={24} />
            </button>
          </div>

          {children}
        </div>
      </div>
    </div>
  );
};

export default FullScreenModal;
