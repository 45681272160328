import React from 'react';

const RadioButton = ({
  id,
  value,
  selectedValue,
  onChange,
  className = '',
  sizeClass = 'size-6',
}: {
  id?: string;
  value: string;
  selectedValue: string | undefined;
  onChange: (value: string) => void;
  className?: string;
  sizeClass?: string;
}) => {
  return (
    <input
      id={id}
      type="radio"
      name="radioButton"
      value={value}
      checked={selectedValue === value}
      onChange={() => onChange(value)}
      className={`${sizeClass} ${className} border-primary bg-color-secondary`}
    />
  );
};

export default RadioButton;
