import React from 'react';
import { Card } from '@epcbuilder/lib/components';
import { Job } from '@epcbuilder/lib/models/jobs';
import { InstallationBooking } from '@/models/job';

const ChosenMeasuresCard = ({ job, installationBooking }: { job?: Job; installationBooking?: InstallationBooking }) => {
  return (
    <Card className="p-6 md:max-w-[616px] lg:max-w-none">
      <div className="relative flex flex-col gap-6">
        <p id="chosen-measures-heading" className="text-xl font-bold leading-none">
          Chosen measures and cost breakdown
        </p>
        <div className="border-neutral dark:border-neutral/30 border-t-1 gap-6" />
        <div className="flex flex-col gap-6 md:flex-row">
          <div className="border-neutral bg-color-white border-1 flex h-[300px] flex-col gap-6 rounded-[16px] border-solid px-4 py-6 sm:h-[24rem] md:w-[70rem] lg:w-[90rem] dark:border-[#516B75]">
            {installationBooking?.selectedImprovement?.items.map((improvementItem) => (
              <div
                key={`improvement-item-${improvementItem.id}`}
                className={`${improvementItem?.improvementItemType?.isDiscount ? 'text-[#008080]' : ''} flex w-full flex-row justify-between`}
              >
                <p id={`improvement-item-${improvementItem.itemText}`} className="flex-1 font-bold">
                  {improvementItem.itemText}
                </p>
                <p id={`improvement-item-${improvementItem.itemCost}`} className="font-bold">
                  {improvementItem.itemCost
                    ? `${improvementItem.improvementItemType?.isDiscount ? '-' : ''} £${improvementItem.itemCost}`
                    : '£0'}
                </p>
              </div>
            ))}
          </div>
          <div className="flex w-full flex-col gap-2 sm:gap-[20px]">
            <div className="flex w-full flex-col gap-2 sm:gap-[20px]">
              <div className="flex size-full h-[7rem] flex-row gap-2 sm:h-full sm:flex-col sm:gap-[20px]">
                <div className="bg-blue flex w-1/2 flex-col items-center justify-center gap-2 rounded-[16px] text-center sm:h-[115px] sm:w-full dark:bg-[#195E55]">
                  <p className="sm:font-bold">Current EPC rating</p>
                  <p id="current-epc-rating" className="text-xl font-bold sm:text-2xl">
                    {job?.currentEpc || 'n/a'}
                  </p>
                </div>
                <div className="bg-blue flex flex-1 flex-col items-center justify-center gap-2 rounded-[16px] text-center sm:h-[115px] dark:bg-[#195E55]">
                  <p className="sm:font-bold">New EPC rating</p>
                  <p id="new-epc-rating" className="text-xl font-bold sm:text-2xl">
                    {installationBooking?.selectedImprovement?.newEpc || 'n/a'}
                  </p>
                </div>
              </div>

              <div className="bg-blue flex size-full h-[5.5rem] flex-col items-center justify-center gap-2 rounded-[16px] sm:h-[10rem] dark:bg-[#195E55]">
                <p className="sm:font-bold">Total cost</p>
                <p
                  id="total-cost"
                  className={`${
                    installationBooking?.selectedImprovement?.cost &&
                    installationBooking!.selectedImprovement!.cost.toString().length > 4
                      ? 'text-xl lg:text-2xl'
                      : 'text-xl sm:text-2xl'
                  } font-bold`}
                >
                  {installationBooking?.selectedImprovement?.cost
                    ? `£${installationBooking?.selectedImprovement.cost}`
                    : '£0'}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default ChosenMeasuresCard;
