import React from 'react';
import { MdLightbulbOutline } from 'react-icons/md';
import { NewButton } from '@epcbuilder/lib/components/Buttons';

const LearnMoreCard = ({ text, url }: { text: string; url: string }) => {
  return (
    <div className="bg-light-dark dark:bg-dark-dark dark:shadow-dark-sm shadow-grey-sm relative flex flex-col gap-4 rounded-[22px] p-6 text-left md:flex-row md:items-center md:text-left lg:flex-col lg:items-center lg:text-left">
      <div className="flex w-full items-center justify-between sm:justify-start md:gap-4 lg:justify-between">
        <p className="font-bold md:w-[270px] lg:w-[212px]">{text}</p>
        <MdLightbulbOutline className="text-color-brand md:order-first lg:order-last xl:order-last" size={48} />
      </div>
      <NewButton
        id="learn-more-button"
        text="Learn more"
        variant="secondary"
        className="mt-4 self-center md:ml-auto md:mt-0 lg:mt-4"
        onClick={() => {
          window.open(url, '_blank');
        }}
      />
    </div>
  );
};

export default LearnMoreCard;
